import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs-compat/Subject';
import { SubscriptionsContainer } from 'src/app/shared/subscriptions-container';
import { OpportunityService } from '../../opportunities-container/opportunity.service';
import { HeaderService } from '../header.service';

declare var $: any;
@Component({
  selector: 'app-assign-opp-modal',
  templateUrl: './assign-opp-modal.component.html',
  styleUrls: ['./assign-opp-modal.component.css']
})
export class AssignOppModalComponent implements OnInit, OnDestroy {

  opportunityList: any;
  public onClose: Subject<any>;
  to_email: any;
  from_email: any;
  errMsg: string;
  oppoList: any;
  toErrMsg: string;
  fromErrMsg: string;
  subs = new SubscriptionsContainer();

  constructor(public bsModalRef: BsModalRef, public headerService: HeaderService, public opportunityService: OpportunityService) {
    this.onClose = new Subject();
  }

  ngOnInit() {
    this.subs.add = this.opportunityService.getalloppList().subscribe(data => {
      this.oppoList = data;
    })
  }

  confirm() {
    const self = this;
    var payload = {
      'from_email': this.from_email,
      'to_email': this.to_email
    }
    this.subs.add = this.headerService.asignOpportunity(payload).subscribe((response) => {
      this.onClose.next(response['data']);
      this.bsModalRef.hide();
    }, error => {
      this.errMsg = error.error.error_msg;
    })
  }

  close() {
    this.onClose.next();
    this.bsModalRef.hide();
  }

  onEvent(event) {
    event.stopPropagation();
  }

  enableValidation(form) {
    const self = this;
    if (this.from_email === undefined || this.from_email.length === 0) {
      return true;
    }
    if ((/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i).test(form.value.fromEmail)) {
      this.fromErrMsg = '';
    } else {
      this.fromErrMsg = 'Enter a valid email';
      return true;
    }
    if (this.to_email === undefined || this.to_email.length === 0) {
      return true;
    }
    if ((/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i).test(form.value.toEmail)) {
      this.toErrMsg = '';
    } else {
      this.toErrMsg = 'Enter a valid email';
      return true;
    }
    if (form.invalid) {
      return true;
    }
  }
  ngOnDestroy(){
    this.subs.dispose();
  }
}
