import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionsContainer } from 'src/app/shared/subscriptions-container';

import { HeaderService } from '../header.service';

declare var $: any;
@Component({
  selector: 'node-informatiom',
  templateUrl: './node-informatiom.component.html',
  styleUrls: ['./node-informatiom.component.css']
})
export class NodeInformatiomComponent implements OnInit, OnDestroy {
  nodeInformation: any;
  nodeInfoTableHeight = window.innerHeight - 120;
  subs = new SubscriptionsContainer();
  objectKeys = Object.keys;
  nodeListDropdown = [];

  constructor(private headerService: HeaderService, private router: Router) { }

  ngOnInit() {
    this.subs.add = this.headerService.nodeInfo().subscribe(response => {
    this.nodeInformation = response;
    });

    setTimeout(() => {
			$('#tableFix').tableHeadFixer();
		}, 500);
  }

  navigateHome() {
    this.router.navigate(['/opportunities']);
  }
  ngOnDestroy(){
    this.subs.dispose();
  }
}
