/**
 * Constant keys.
 */
export const GAIA: string = 'gaia';
export const IS_GAIA_ENABLED: string = 'is_gaia_enabled';
export const GAIA_INDEX_CONVERSION_RATE: string = 'gaia_index_conversion_rate';
export const LLM_PREF: string = 'llm_pref';
export const DESIRED_GAIA_GEN_ANSWERS_GPT4: string = 'desired_gaia_gen_answers_gpt4';
export const DESIRED_GAIA_GEN_ANSWERS_GPT3_5: string = 'desired_gaia_gen_answers_gpt3_5';
export const IS_100_GB_INDEX_STORAGE_PURCHASED: string = 'is_100_gb_index_storage_purchased';

/**
 * Constant values.
 */
export const GPT4_0: string = 'GPT 4.0';
export const GPT4_0_VALUE: string = 'gpt4_0';
export const GPT3_5_TURBO: string = 'GPT 3.5 Turbo';
export const GPT3_5_TURBO_VALUE: string = 'gpt3_5_turbo';
export const BOTH_GPT4_0_GPT3_5_TURBO: string = 'Both GPT 4.0 & GPT 3.5 Turbo';
export const BOTH_GPT4_0_GPT3_5_TURBO_VALUE: string = 'both_gpt4_0_gpt3_5_turbo';
export const DEFAULT_LLM_PREFERENCE: string = GPT4_0_VALUE;
export const DEFAULT_GAIA_INDEX_CONVERSION_RATE: number = 10;
export const DEFAULT_DESIRED_GPT4_0_GENERATIVE_ANSWERS: number = 10000;
export const DEFAULT_DESIRED_GPT3_5_GENERATIVE_ANSWERS: number = 10000;
export const DEFAULT_IS_GAIA_ENABLED: boolean = false;
export const DEFAULT_IS_100_GB_INDEX_STORAGE_PURCHASED: boolean = true;