export enum ModalMode {
  None = 'none',
  Add = 'add',
  Edit = 'edit',
  Clone = 'clone'
}

export interface ModalDataModel {
  type: string;
  mode: ModalMode;
  site: any;
}
