import {Injectable} from '@angular/core';
import {SimpleSizeNodes, SimpleSizerStateModel, SimpleSizerStateProperties} from '../models/simple-sizer-state.model';
import {BehaviorSubject} from 'rxjs';
import {OpportunityService} from '../../opportunities-container/opportunity.service';
import {Observable} from 'rxjs/Observable';
import {Opportunity} from '../../models/opportunity';
import {OppoDetailsService} from '../../opportunity-details/oppo-details.service';
import {SiteData} from '../models/simple-sizer-site-data.model';
import {ChangedDataWorkloadModel} from '../models/simple-sizer-workload.model';
import {ConfigService} from '../../shared/services/config.service';
import {SizerType} from '../../models/generic.model';
import { defaultPlatformType } from './config';

@Injectable({
  providedIn: 'root'
})
export class SimpleSizeDatastoreService {

  public state: SimpleSizerStateModel = {
    selectedOpportunity: undefined,
    workloadList: [],
    nodes: {
      compute: [],
      normal: [],
      robo: []
    },
    selectedVendor: {
      name: '',
      nodeList: []
    },
    isLazySizingRequired: false,
    sizing_results: {},
    siteData: {
      site: {
        internal_notes: undefined,
        location: [],
        name: '',
        settings: undefined,
        site_note: '',
        tco_settings: undefined,
        workload_list: [],
        site: null,
        id: null,
        robo_site: false
      },
      result: {}
    },
    changedWorkloadData: {add_wl: [], edit_wl: [], del_wl: []},
    isSimpleSizer: false,
    sizerType: SizerType.Default,
    selectedNode: 'auto'
  };

  private selectedOpportunitySource: BehaviorSubject<Opportunity> = new BehaviorSubject<Opportunity>(this.state.selectedOpportunity);
  selectedOpportunity$: Observable<Opportunity> = this.selectedOpportunitySource.asObservable();

  private isLazySizingRequiredSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.state.isLazySizingRequired);
  isLazySizingRequired$: Observable<boolean> = this.isLazySizingRequiredSource.asObservable();

  private nodesSource: BehaviorSubject<SimpleSizeNodes> = new BehaviorSubject(this.state.nodes);
  nodes$ = this.nodesSource.asObservable();

  private selectedVendorSource: BehaviorSubject<{ name: string, nodeList: string[][] }> = new BehaviorSubject(this.state.selectedVendor);
  selectedVendor$ = this.selectedVendorSource.asObservable();

  private selectedNodeSource: BehaviorSubject<string> = new BehaviorSubject<string>('auto');
  selectedNode$: Observable<string> = this.selectedNodeSource.asObservable();

  private workloadListSource: BehaviorSubject<any[]> = new BehaviorSubject(this.state.workloadList);
  workloadList$ = this.workloadListSource.asObservable();

  private sizingResultsSource: BehaviorSubject<any> = new BehaviorSubject<any>(this.state.sizing_results);
  sizingResults$: Observable<any> = this.sizingResultsSource.asObservable();

  private siteDataSource: BehaviorSubject<SiteData> = new BehaviorSubject<SiteData>(this.state.siteData);
  siteData$ = this.siteDataSource.asObservable();

  private changedWorkloadDataSource: BehaviorSubject<ChangedDataWorkloadModel> =
    new BehaviorSubject<ChangedDataWorkloadModel>(this.state.changedWorkloadData);
  changedworkloadData$ = this.changedWorkloadDataSource.asObservable();

  private isSimpleSizerSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSimpleSizer$: Observable<boolean> = this.isSimpleSizerSource.asObservable();

  constructor(
    private opportunityService: OpportunityService,
    private oppoDetailsService: OppoDetailsService,
    private configService: ConfigService
  ) {
    this.initData();
  }

  private initData() {
    this.onWorkloadDataChanged(false, this.state.changedWorkloadData);
    this.onSizingResultsUpdated();
    this.initNodesList();
  }

  private updateState(propertyToUpdated: SimpleSizerStateProperties, valueToUpdate: any) {
    this.state = Object.freeze(Object.assign({}, this.state, Object.freeze({[propertyToUpdated]: valueToUpdate})));
  }

  public onNodesListUpdated(nodesList: SimpleSizeNodes) {
    this.updateState(SimpleSizerStateProperties.nodes, nodesList);
    this.nodesSource.next(this.state.nodes);
  }

  public onVendorSelected(vendor: any, isOnlyVendorChanged=false) {
    if(vendor && vendor.name) {
      this.updateState(SimpleSizerStateProperties.selectedVendor, vendor);
      this.selectedVendorSource.next(vendor);
      if(isOnlyVendorChanged)
        this.onNodeSelected(defaultPlatformType)
    }
  }

  public onNodeSelected(node: string) {
    this.updateState(SimpleSizerStateProperties.selectedNode, node);
    this.selectedNodeSource.next(node);
  }

  public onWorkloadListUpdated(workloadList: any[]) {
    this.updateState(SimpleSizerStateProperties.workloadList, workloadList);
    this.workloadListSource.next(workloadList);
  }

  public onWorkloadDataChanged(showLazySizingDialog: boolean, changedWorkloadData?: ChangedDataWorkloadModel): void {
    if (changedWorkloadData !== undefined) {
      this.updateState(SimpleSizerStateProperties.changedWorkloadData, changedWorkloadData);
      this.changedWorkloadDataSource.next(changedWorkloadData);
    }
    this.updateState(SimpleSizerStateProperties.isLazySizingRequired, showLazySizingDialog);
    this.isLazySizingRequiredSource.next(showLazySizingDialog);
  }

  public onSizingResultsUpdated(): void {

    if (this.state.selectedOpportunity) {
      this.oppoDetailsService.getSitesListV2(this.state.selectedOpportunity)
        .subscribe(result => {
          this.updateState(SimpleSizerStateProperties.sizing_results, result[0].result);
          this.sizingResultsSource.next(result[0].result);
          this.onWorkloadListUpdated([...result[0].site.workload_list])
        }, error => {
          console.error(error);
        });
    }

  }

  public onOpportunitySelected(opportunity: Opportunity): void {
    this.updateState(SimpleSizerStateProperties.selectedOpportunity, opportunity);
    this.selectedOpportunitySource.next(opportunity);
    this.getWorkloadData();
  }

  onOpportunityDeSelected() {

    this.updateState(SimpleSizerStateProperties.selectedOpportunity, undefined);
    this.selectedOpportunitySource.next(undefined);

    this.updateState(SimpleSizerStateProperties.siteData, {site: {}, result: {}});
    this.siteDataSource.next(<SiteData>{site: {}, result: {}});

    this.updateState(SimpleSizerStateProperties.workloadList, []);
    this.workloadListSource.next([]);

    this.updateState(SimpleSizerStateProperties.changedWorkloadData, {add_wl: [], edit_wl: [], del_wl: []});
    this.changedWorkloadDataSource.next({add_wl: [], edit_wl: [], del_wl: []});

    this.updateState(SimpleSizerStateProperties.isLazySizingRequired, false);
    this.isLazySizingRequiredSource.next(false);

    this.updateState(SimpleSizerStateProperties.sizing_results, {});
    this.sizingResultsSource.next({});

  }


  public onSiteDataUpdated(siteData: SiteData): void {
    this.updateState(SimpleSizerStateProperties.siteData, siteData);
    this.siteDataSource.next(siteData);
  }

  private initNodesList() {
    this.opportunityService.getNodePrefList()
      .subscribe((nodeData: any) => {
        const nodesList: SimpleSizeNodes = {normal: [], compute: [], robo: []};
        for (const vendorName in nodeData.normal) {
          if (nodeData.normal.hasOwnProperty(vendorName)) {
            nodesList.normal.push({
              name: vendorName,
              nodeList: nodeData.normal[vendorName]
            });
          }
        }
        for (const vendorName in nodeData.compute) {
          if (nodeData.compute.hasOwnProperty(vendorName)) {
            nodesList.compute.push({
              name: vendorName,
              nodeList: nodeData.compute[vendorName]
            });
          }
        }
        this.onNodesListUpdated(nodesList);
        this.updateState(SimpleSizerStateProperties.selectedVendor, nodesList.normal[0]);
      }, error => {
        console.error(error);
        this.onNodesListUpdated({normal: [], compute: [], robo: []});
      });
  }

  private getWorkloadData() {
    this.oppoDetailsService
      .getSitesListV2(this.state.selectedOpportunity)
      .subscribe(response => {
        this.onSiteDataUpdated(response[0]);
        this.onWorkloadListUpdated(response[0].site.workload_list);
        this.onNodeSelected(response[0].site.settings.node_preference);
        this.onVendorSelected(this.state.nodes.normal.find(e => e.name === response[0].site.settings.node_config.vendor));
        this.updateState(SimpleSizerStateProperties.sizing_results, response[0].result);
        this.sizingResultsSource.next(response[0].result);
      });
  }


  onSimpleSizerActivated() {
    const userDetail = this.configService.fetchUserDetails();
    if (userDetail) {
      setTimeout(() => {
        this.updateState(SimpleSizerStateProperties.isSimpleSizer, userDetail.simple_sizer_auth);
        this.updateState(SimpleSizerStateProperties.sizerType, userDetail.simple_sizer_auth ? SizerType.Commercial : SizerType.Default);
        this.isSimpleSizerSource.next(userDetail.simple_sizer_auth);
      }, 0);
    }
  }

  public onAdvancedNodeUpdate(): void {
    this.sizingResultsSource.next({});
  }

}
