import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-popular-nodes-graph',
  templateUrl: './popular-nodes-graph.component.html',
  styleUrls: ['./popular-nodes-graph.component.css']
})
export class PopularNodesGraphComponent implements OnInit {

  @Output() nodeMessageEvent = new EventEmitter<boolean>();
  @Input() popular_nodes: any;
  analysisbar: any;
  colors = ['#93c83f'];
  ticks = [];
  noDataFlag: boolean = false;
  lineGraphData = [];
  nodeDetailsData: any;
  each_month_ct: any;
  options: any;
  legendsVisibility = {};
  oppNode: any;
  requiredData: any;
  storagePlot: any;
  yearsGraphWidth = innerWidth - 300;

  addTicksToNode: any;
  TickData: any;

  constructor(public el: ElementRef) { }

  ngOnInit() {
    this.prepareGraphData();
    this.legendEvent = this.legendEvent.bind(this);
    setTimeout(() => {
      this.graphDynamicWidth();
    }, 300);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.popular_nodes !== undefined && changes.popular_nodes.currentValue !== undefined && Object.keys(changes.popular_nodes.currentValue).length !== 0) {
      this.popular_nodes = changes.popular_nodes.currentValue;
      this.prepareGraphData();
      this.noDataFlag = false;
    } else {
      this.noDataFlag = true;
    }
    this.nodeMessageEvent.emit(this.noDataFlag);
  }

  onResizeGraph() {
    this.graphDynamicWidth();
  }

  private graphDynamicWidth() {
    const elementId = 'graph_container'
    const graph_node = $('#' + elementId);
    const graph_node_width = graph_node.width() || undefined;
    graph_node.find('.place_holder_width').css('width', graph_node_width);
    const $canvas = graph_node.find('canvas');
    const width = graph_node_width ? Math.min($canvas.attr('width'), graph_node_width) : graph_node_width;
    $canvas.css('width', width);

    if (this.oppNode) {
      this.oppNode.setupGrid();
      this.oppNode.resize();
      this.oppNode.draw();
      this.setupLegendActions();
    }
  }

  private canvasSize() {
    const containerWidth = $('.yearly-graph').width();
    $('#yearsGraph').css('width', containerWidth);
  }
  setToolTip() {
    const self = this;
    $(this.analysisbar).bind({
      plothover: function (event, pos, item) {
        if (item) {
          if ((this.previousLabel !== item.series.label) || (this.previousPoint !== item.dataIndex)) {
            this.previousPoint = item.dataIndex;
            this.previousLabel = item.series.label;
            $('#tooltip').remove();

            const x = parseFloat(parseFloat(item.datapoint[0]).toFixed(2));
            const y = parseFloat(parseFloat(item.datapoint[1]).toFixed(2));

            const color = item.series.color;
            //  const str = item.series.label + ' : <strong>' + y + '</strong>';
            const str = ' <strong>' + self.TickData[x][1] + ': ' + y + ' </strong>';
            $('<div id="tooltip">' + str + '</div>').css({
              position: 'absolute',
              display: 'none',
              top: item.pageY - 35,
              left: item.pageX - 85,
              border: '2px solid ' + color,
              padding: '3px',
              'font-size': '11px',
              'border-radius': '5px',
              'background-color': '#fff',
              'font-family': 'SourceSansPro-Regular',
              opacity: 0.9
            }).appendTo('body').fadeIn(200);
          } else {
            //  $("#tooltip").remove();
            this.previousPoint = null;
          }

        } else {
          $('#tooltip').remove();
          this.previousPoint = null;
        }
      }


    });
  }
  setColor(series) {
    const elements = $(this.el.nativeElement).find('.yearly-graph .legendLabel a');
    for (let i = 0; i < series.length; ++i) {
      elements[i].style.color = series[i].color;
    }
  }

  setupLegendActions() {
    const self = this;
    setTimeout(() => {
      $('.yearly-graph .chart-dynamic-legend').bind('click', function (evt) {
        const $this = $(this);
        const data = $(this).data('test');
        const text_val = $this.text();
        if (data.chartName === 'oppNode') {
          self.togglePlot(data.index - 1, self.oppNode, data.chartName);
          evt.stopImmediatePropagation();
        }
      });
    }, 100);
  }

  togglePlot(seriesIdx, plotObject, plotName) {
    const someData = plotObject.getData();
    let max = 0;
    // if (plotName !== 'StroagePlot') {
    if (someData[seriesIdx].lines) {
      someData[seriesIdx].lines.show = !someData[seriesIdx].lines.show;
      someData[seriesIdx].points.show = !someData[seriesIdx].points.show;
      // someData[seriesIdx].bars.show = false;
      // someData[seriesIdx].bars.fill = 0;
      this.legendsVisibility[someData[seriesIdx].label] = someData[seriesIdx].lines.show;
    }
    // }
    someData.forEach(data => {
      if (data.bars.show || data.lines.show) {
        data.data.forEach(barData => {
          max = Math.max(max, parseFloat(barData[1]));
        });
      }
    });
    plotObject.setData(someData);

    const opts = plotObject.getOptions();
    opts.yaxes[0].max = max + (max / 10);
    plotObject.setupGrid();
    plotObject.draw();
    this.setupLegendActions();
    this.setColor(someData);
  }

  prepareGraphData() {
    const self = this;
    this.lineGraphData = [];
    this.TickData = [];
    let data = []
    if (this.popular_nodes !== undefined) {
      data = self.popular_nodes;
      self.popular_nodes.map((node_count, index) => { 
          this.TickData.push([index, node_count[0]]);
          this.noDataFlag = false;
        })
    }
    this.lineGraphData.push({
      label: "Top 10 popular nodes",
      data: data,
      tooltip: true,
      color: this.colors[0],
      tipselector: "Top 10 popular nodes",
      idx: 0,
      _name: 'oppNode'
    });
    this.barOptions();
    this.analysisbar = $(this.el.nativeElement).find('#yearsGraph').empty();


    setTimeout(() => {
      try {
        self.oppNode = $.plot(this.analysisbar, this.lineGraphData, this.options);
        const series = self.oppNode.getData();
        this.setColor(series);
        self.setupLegendActions();
      } catch (e) {
      }

    }, 100);
    this.legendsVisibility = this.lineGraphData.reduce((value, legend) => {
      value[legend.label] = true;
      return value;
    }, {});
    this.setToolTip();

  }

  legendEvent(label, series) {
    const data = { index: series.idx, chartName: series._name };
    const dataStr = JSON.stringify(data);
    const classes = 'chart-dynamic-legend ' + (this.legendsVisibility[label] ? '' : 'disableClass');
    const str = '<a href="javascript:void(0)" class="' + classes + '" data-test=' + dataStr + '>' + label + '</a>';
    return str;
  }

  barOptions() {
    this.options = {
      canvas: true,
      series: {
        bars: {
            show: true
        }
    },
    bars: {
      show: true,
      align: "center",
      barWidth: 0.5
    },
      stack: false,
      colors: '#93c83f',
      legend: {
        noColumns: 0,
        position: 'se',
        labelBoxBorderColor: 'none',
        labelFormatter: this.legendEvent.bind(this)
      },
      xaxes: [{
        axisLabel: 'Nodes',
        axisLabelUseCanvas: true,
        axisLabelColour: '#898989',
        axisLabelFontSizePixels: 11,
        axisLabelFontFamily: 'SourceSansPro-Regular',
        axisLabelPadding: 10,
        mode: 'categories',
        ticks: this.TickData,
        minTickSize: [1, 'minute'],
        timeformat: '%H:%M',
        reserveSpace: false,
        tickColor: '#eee',
        color: '#898989',
        timezone: 'browser',
        min: -0.5,
        max: 9.5
      },
      {
        show: false,
        mode: 'categories',
        reserveSpace: false,
      }],
      yaxes: [{
        axisLabel: 'Node Count ' + String.fromCharCode(10230),
        axisLabelUseCanvas: true,
        axisLabelFontSizePixels: 11,
        axisLabelFontFamily: 'SourceSansPro-Regular',
        axisLabelColour: '#898989',
        axisLabelPadding: 5,
        color: '#898989',
        tickColor: '#eee',
        ticks: 12,
        minTickSize: [5, "minute"],
        tickDecimals: 0,
        min: 0
      }, {
        position: 'left',
        axisLabelUseCanvas: true,
        axisLabelFontSizePixels: 10,
        axisLabelFontFamily: 'SourceSansPro-SemiBold',
        axisLabelPadding: 5,
        min: 0,
        tickColor: '#eee'
      }],
      grid: {
        hoverable: true,
        clickable: true,
        borderColor: '#d7d2c4',
        borderWidth: 0.2,
        backgroundColor: 'transparent'
      },
      backgroundColor: {
        colors: 'transparent'
      }
    };
  }
  toUpperUseCase(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

}
