import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AnalyticsComponent} from './analytics/analytics.component';
import {NodeInformatiomComponent} from './header/node-informatiom/node-informatiom.component';
import {SizerType} from './models/generic.model';

export const AppRoutes: Routes = [
  {
    path: 'opportunities',
    loadChildren: './opportunities-container/opportunities.module#OpportunitiesModule',
    data: {
      viewName: 'opportunities',
      sizerType: SizerType.Default
    }
  },
  {
    path: 'site',
    loadChildren: './opportunity-details/oppo-details.module#OppoDetailsModule',
    data: {
      viewName: 'opportunity-details'
    }
  },
  {
    path: 'nodeInformation',
    component: NodeInformatiomComponent,
    data: {
      viewName: 'nodeInformation'
    }
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    data: {
      viewName: 'analytics'
    }
  },
  {
    path: 'all-opportunities',
    loadChildren: './commercial-sizer/commercial-sizer.module#CommercialSizerModule',
    data: {
      viewName: 'commercial-sizer',
      sizerType: SizerType.Commercial
    }

  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/opportunities'
  }    // <-- Any unknow path / route / view name
];

export const RouterConfigModule: ModuleWithProviders = RouterModule.forRoot(AppRoutes, {
  // enableTracing: (!environment.production), // <-- debugging purposes only
  useHash: true
});
