import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http/http.service';
import { RestService } from '../../shared/services/http/rest.service';
import { ConfigService } from '../shared/services/config.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService extends RestService{

  queryName = 'ANALYTICSGRAPH';
  ajaxLoaderEnabled = true;
  url = environment.http.baseUrl;

  constructor(httpService: HttpService, private configService: ConfigService) {
    super(httpService);
  }

  getUrlForDownload() {
    return this.url;
  }

  getOpportunitiesCount(payload) {
    this.baseUrl = this.url + '/sizeranalytics/';
      return this.save('', '', payload);
  }
}
