import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http/http.service';
import {RestService} from '../../shared/services/http/rest.service';
import {ConfigService} from '../shared/services/config.service';
import {environment} from '../../environments/environment';
import {SizerType} from '../models/generic.model';

@Injectable()
export class OpportunityService extends RestService {
    url = environment.http.baseUrl;
    // baseUrl = environment.http.baseUrl + '/opportunity';
    queryName = 'OPPORTUNITY';
    ajaxLoaderEnabled = true;
    opportunityList = [];
    pageData = {
        pages: 0,
        pageNum: 10,
        searchStr: '',
        tabSelected: 'active',
        selectAllFlag: true,
        advanceSearchFields: {
            'id': [],
            'name': [],
            'version': [],
            'customer_name': [],
            'created_by': [],
            'sfdc_id': [],
            'last_modified_by': []
        },
        sortInfo: {
            isDesc: false,
            direction: 0,
            column: ''
        }
    };

    constructor(httpService: HttpService, private configService: ConfigService) {
        super(httpService);
    }

    resizeOpprtunity(payload) {
        this.baseUrl = this.url + '/opportunity/' + payload.id + '/resize';
        delete payload['resizeOption'];
        delete payload['adminFlag'];
        return this.save('', '', payload);
    }

    addOpportunity(payload) {
        this.baseUrl = this.url + '/opportunity/';
        delete payload['resizeOption'];
        delete payload['adminFlag'];
        payload['action'] = 'add-opportunity';
        return this.save('', '', payload);
    }

    importWorkloadJson(payload) {
        this.baseUrl = (environment.enableHeterogeneousClusters === true) ? this.url + '/opportunity/v2/import-json' : this.url + '/opportunity/import-json';
        delete payload['resizeOption'];
        delete payload['adminFlag'];
        return this.save('', '', payload);
    }

    editOpportunity(payload) {
        this.baseUrl = this.url + '/opportunity';
        delete payload['resizeOption'];
        delete payload['adminFlag'];
        return this.update({ id: payload.id }, '', payload);
    }

    cloneOpportunity(payload) {
        this.baseUrl = this.url + '/opportunity/clone';
        delete payload['resizeOption'];
        delete payload['adminFlag'];
        return this.save({ id: payload.id }, '', payload);
    }

    getOpportunityList(loaderId, sizerType?: SizerType) {
        this.baseUrl = this.url + '/opportunity/?fetch=5000';
        if (sizerType === SizerType.Commercial) {
          this.baseUrl += `&commercial=true`;
        }
        return this.query('', '', loaderId);
    }
    
    getSingleOpportunity(id:number) {
        this.baseUrl = this.url + '/opportunity/' + id;
        return this.query('', '', 'SINGLE_OPP_LOADING');
    }

    getalloppList(){
        this.baseUrl = this.url + '/opportunity/';
        return this.query('','','');
    }
    getArchiveList(isSimpleSizer=false) {
        this.baseUrl = this.url + `/opportunity/?archive=true${isSimpleSizer?'&commercial=true':''}`;
        return this.query();
    }
    
    getOpportunityById(id) {
        this.baseUrl = this.url + '/opportunity/' + id;
        return this.query();
    }

    deleteOpportunity(id) {
        this.baseUrl = this.url + '/opportunity';
        return this.delete({ id: id });
    }

    changeOppoDataPatch(payload) {
        this.baseUrl = this.url + '/opportunity/';
        return this.patch('', '', payload);
    }

    getResiliencyList() {
        this.baseUrl = this.url + '/envsetup/resiliency';
        return this.query();
    }

    getLocationList() {
        this.baseUrl = this.url + '/envsetup/location';
        return this.query();
    }

    getPrePolicyList() {
        this.baseUrl = this.url + '/envsetup/prepolicy';
        return this.query();
    }

    getNodePrefList() {
        this.baseUrl = this.url + '/envsetup/nodes';
        return this.query();
    }

    getUseCaseTypeList() {
        this.baseUrl = this.url + '/envsetup/workloads';
        return this.query();
    }

    setPrePolicyListData(policyList) {
        this.configService.setPrepolicyListData(policyList);
    }

    setResiliencyList(resList) {
        this.configService.setResiliencyList(resList);
    }

    setLocationList(locList) {
        this.configService.setLocationList(locList);
    }

    setNodePrefList(nodeList) {
        this.configService.setNodePrefListData(nodeList);
    }

    setUseCaseTypeListData(useCase) {
        this.configService.setUseCaseTypeListData(useCase);
    }

    setOpportunityData(opportunity) {
        this.configService.setOpportunityData(opportunity);
    }

    setOpportunityList(oppoList) {
        this.configService.setOpportunityList(oppoList);
    }

    setOppoPageInfo(pageData) {
        this.pageData = pageData;
    }

    getOppoPageInfo() {
        return this.pageData;
    }

    getOppoList() {
        return this.configService.getOpportunityList();
    }

    fetchVersion() {
        return this.configService.fetchVersion();
    }

    fetchUserDetails() {
        return this.configService.fetchUserDetails();
    }
    getPrePolicyListData() {
        return this.configService.getPrepolicyListData();
    }
    addNodeConfiguration(nodeSetDetails) {
        this.baseUrl = this.url + '/opportunity/' + nodeSetDetails.opp_id + '/nodeconfig/';
        return this.save('', '', nodeSetDetails);
    }
    getNodeConfiguration(oppurtunity_id) {
        this.baseUrl = this.url + '/opportunity/' + oppurtunity_id + '/nodeconfig/'
        return this.query();
    }
    getResiliencyListData() {
        return this.configService.getResiliencyListData();
    }
    getLocationListData() {
        return this.configService.getLocationListData();
    }


}
