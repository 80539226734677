import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ConfirmModalComponent],
  entryComponents: [ConfirmModalComponent]
})
export class UiComponentsModule { }
