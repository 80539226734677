import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs-compat/Subject';
import { SubscriptionsContainer } from 'src/app/shared/subscriptions-container';
import { HeaderService } from '../header.service';

declare var $: any;
@Component({
  selector: 'app-transfer-opp-modal',
  templateUrl: './transfer-opp-modal.component.html',
  styleUrls: ['./transfer-opp-modal.component.css']
})
export class TransferOppModalComponent implements OnInit, OnDestroy {

  public onClose: Subject<any>;
  opportunity_name: any;
  opportunity_username: any;
  errMsg: any;
  subs = new SubscriptionsContainer();

  constructor(public bsModalRef: BsModalRef , public headerService: HeaderService) {
    this.onClose = new Subject();
  }

  ngOnInit() {
  }

  confirm() {
    const self = this;
    var payload = {
      opportunity_name: this.opportunity_name,
      opportunity_username: this.opportunity_username
    }
    this.subs.add = this.headerService.transferOpportunity(payload).subscribe((response) => {
      this.onClose.next(response['data']);
      this.bsModalRef.hide();
    }, error => {
      this.errMsg = error.error['error_msg'];
    })
}

  close() {
    this.onClose.next();
    this.bsModalRef.hide();
  }

  onEvent(event) {
		event.stopPropagation();
  }

  enableValidation(form) {
    const self = this;
    if (form.invalid) {
      return true;
    }
    if(this.opportunity_name === undefined || this.opportunity_name.length ===0){
        return true;
    }
    if(this.opportunity_username === undefined || this.opportunity_username.length === 0){
      return true;
    }
  }
  ngOnDestroy(){
    this.subs.dispose();
  }

}
