import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class RestService {

    baseUrl = 'rest_url';
    queryName = 'REST_SERVICE_NAME_PREFIX';
    headers = {};
    ajaxLoaderEnabled = false;

    constructor(private httpService: HttpService) {}

    private getDefaultConfig(requestMethod, queryParams, queryPrefix, loaderId) {
        const headers = this.ajaxLoaderEnabled ? {
            ...this.headers,
            ajax_loader: (loaderId === undefined) ? `${queryPrefix}_${this.queryName}` : loaderId
        } : this.headers;
        return {
            method: requestMethod,
            params: queryParams,
            headers: headers
        };
    }

    getUrl(pathParams, reqMethod: string) {
        if (pathParams) {
            if (pathParams.props && pathParams.props.length) {
                let url = this.baseUrl;
                pathParams.props.forEach( propName => {
                    url += '/' + pathParams[propName];
                } );
                return url;
            } else {
                return `${this.baseUrl}/${pathParams.id}`;
            }
        } else {
            return this.baseUrl;
        }
    }

    get(pathParams?, queryParams?, loaderId?): Observable<any> {
        const config: any = this.getDefaultConfig('GET', queryParams, 'GET', loaderId);
        config.url = this.getUrl(pathParams, config.method);
        return this.httpService.processRequest(config);
    }

    query(pathParams?, queryParams?, loaderId?): Observable<any> {
        const config: any = this.getDefaultConfig('GET', queryParams, 'QUERY', loaderId);
        config.url = this.getUrl(pathParams, config.method);
        return this.httpService.processRequest(config);
    }

    save(pathParams?, queryParams?, payload?, loaderId?): Observable<any> {
        const config: any = this.getDefaultConfig('POST', queryParams, 'CREATE', loaderId);
        config.url = this.getUrl(pathParams, config.method);
        config.body = payload;
        return this.httpService.processRequest(config);
    }

    patch(pathParams?, queryParams?, payload?, loaderId?): Observable<any> {
        const config: any = this.getDefaultConfig('patch', queryParams, 'PATCH', loaderId);
        config.url = this.getUrl(pathParams, config.method);
        config.body = payload;
        return this.httpService.processRequest(config);
    }

    update(pathParams?, queryParams?, payload?, loaderId?): Observable<any> {
        const config: any = this.getDefaultConfig('PUT', queryParams, 'UPDATE', loaderId);
        config.url = this.getUrl(pathParams, config.method);
        config.body = payload;
        return this.httpService.processRequest(config);
    }

    delete(pathParams?, queryParams?, payload?, loaderId?): Observable<any> {
        const config: any = this.getDefaultConfig('DELETE', queryParams, 'DELETE', loaderId);
        config.url = this.getUrl(pathParams, config.method);
        config.body = payload;
        return this.httpService.processRequest(config);
    }

}
