import { Directive,Input} from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[customMin][formControlName],[customMin][formControl],[customMin][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true}]
})
export class MinValidatorDirective implements Validator {
 // @Input() MinValidator;
 @Input()
 customMin: number;
  
 validate(c: FormControl): {[key: string]: any} {
    let v = c.value;
    return ( v < this.customMin)? {"customMin": true} : null;
  }
}
