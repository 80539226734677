export class AjaxLoaderOptions {
    inline = false;
    hideOnProgress = false;
    hideOnError = false;
    hideOnSuccess = true;
    hideOnCanceled = true;
    progressText = 'Please wait, request is in progress... ';
    successText = 'API Call is processed... ';
    errorText = 'Unknown Error in API call... ';
}


// export interface IAjaxLoaderOptions {
//     inline: boolean;
//     hideOnProgress: boolean;
//     hideOnError: boolean;
//     hideOnSuccess: boolean;
//     progressText: string;
//     successText: string;
//     errorText: string;
// }
