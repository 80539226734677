import {SimpleSizerWorkloadMap} from '../models/util.model';

export const allWorkloads: SimpleSizerWorkloadMap[] = [
  {
    type: 'iba',
    subtype: 'vm',
    label: 'VM',
    dcr: 2
  },
  {
    type: 'iba',
    subtype: 'unstructured',
    label: 'Physical',
    dcr: 2,
  },
  {
    type: 'iba',
    subtype: 'sql',
    label: 'DB',
    dcr: 5,
  },
  {
    type: 'iba',
    subtype: 'non_dedupable',
    label: 'Encrypted / Compressed',
    dcr: 2,
  },
  {
    type: 'nas',
    subtype: 'netapp_unstructured',
    label: 'NAS',
    dcr: 1,
  },
  {
    type: 'app_dump',
    subtype: 'other_db',
    label: 'AppDump / BackupTarget',
    dcr: 5,
  },
  {
    type: 'app_dump',
    subtype: 'comp_or_encr',
    label: 'Image / Video / Non-reducible',
    dcr: 2,
  },
  {
    type: 'iba',
    subtype: 'other_db',
    label: 'Others',
    dcr: 5,
  }
];

export const backupFrequency = [
  {
    value: '4h',
    label: '4 Hours'
  },
  {
    value: '12h',
    label: '12 Hours'
  },
  {
    value: '1D',
    label: '1 Day'
  },
  {
    value: '1W',
    label: '1 Week'
  },
  {
    value: '1M',
    label: '1 Month'
  }
];

export const retention = [
  {
    value: '1D',
    label: '1 Day'
  },
  {
    value: '7D',
    label: '7 Days'
  },
  {
    value: '14D',
    label: '14 Days'
  },
  {
    value: '30D',
    label: '30 Days'
  },
  {
    value: '90D',
    label: '90 Days'
  },
  {
    value: '180D',
    label: '180 Days'
  },
  {
    value: '365D',
    label: '365 Days'
  }
];
export const defaultPlatformType= 'auto'

export const nasStaticValues={
  "nas_files": 1,
  "peak_file_volume": 1,
  "software_version": "incr",
}

export const deletionKeys = [
  "nas_files",
  "peak_file_volume",
  "software_version",
  "peak_data",
  "backupFrequencyInput",
  "backupRetentionInput",
];

export const workloadInitialData=(id:number)=>({
  name: '',
  data_size: 1,
  wl_subtype: '',
  policy: '',
  annual_growth_rate: 10,
  archive: {},
  backup_enabled: true,
  backup_window: ['00:00', '00:00'],
  cdp: {},
  cloud_direct: false,
  custom_policy: false,
  daily_change_rate: 0,
  dr_orch: false,
  replication: [],
  site_id: id,
  sizing_enabled: true,
  vm_files: 3,
  wl_type: '',
  datahawk: {
    enabled: false,
    logSearch: {
      enabled: false
    },
    ransomwareClassification: {
      enabled: false,
      numVMsToScan: 1,
      medianSizeOfVM: 300,
      hoursRequiredToScanAllVMs: 18.16,
      scanWindow: 7,
    },
    threatScanning: {
      enabled: false,
      medianSizeOfVM: 75,
      hoursRequiredToScanOneTB: 21.33,
      scanWindow: 7,
    }
  }
})

export const dbLogDetails = {
  nameSuffix: "_DBLOGS",
  wl_type: "iba",
  wl_subtype: "archive_log",
};

export const sizingReportYears=[
  {
    value:1,
    label:'One'
  },
  {
    value:2,
    label:'Two'
  },
  {
    value:3,
    label:'Three'
  },
  {
    value:4,
    label:'Four'
  },
  {
    value:5,
    label:'Five'
  },
]