import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs-compat/Subject';

import { OpportunityService } from '../../opportunities-container/opportunity.service';
import { ConfigService } from '../../shared/services/config.service';
import { HeaderService } from '../header.service';

import { Router } from '@angular/router';
import { SubscriptionsContainer } from 'src/app/shared/subscriptions-container';
 
declare var $: any;
@Component({
  selector: 'view-as-modal',
  templateUrl: './view-as-modal.component.html',
  styleUrls: ['./view-as-modal.component.css']
})
export class ViewAsModalComponent implements OnInit, OnDestroy {

  public onClose: Subject<any>;
  opportunity_useremail: any = '';
  oppoList: any;
  activeList: any[];
  emailId: any;
  emailList: any;
  emailIds: any;
  userDetails: any;
  toErrMsg: string;
  subs = new SubscriptionsContainer();

  constructor(public bsModalRef: BsModalRef, private headerService: HeaderService , private opportunityService: OpportunityService, private configService: ConfigService, private router: Router) { 
    this.onClose = new Subject();
  }

  ngOnInit() {
    const self = this;
    this.emailList = this.emailIds;
  }
  
  confirm() {
    const payload = {
      'email' : this.opportunity_useremail
    }
    this.subs.add = this.headerService.viewAs(payload).subscribe( response => {
      this.configService.setUserDetails(response['data']);
      this.userDetails = this.configService.fetchUserDetails();
      this.onClose.next(this.userDetails);
      this.bsModalRef.hide();
    })
  }

  close() {
    this.onClose.next();
    this.bsModalRef.hide();
  }

  onEvent(event) {
		event.stopPropagation();
  }

  filterdOptions = [];
  filterUsers() {
    this.filterdOptions = this.emailList.filter((item: any) => {
      return item.toLowerCase().indexOf(this.opportunity_useremail.toLowerCase()) !== -1;
    });
    if(this.opportunity_useremail.length === 0) {
      this.filterdOptions = [];
    }
    if(this.filterdOptions.includes(this.opportunity_useremail)) {
      this.filterdOptions = [];
    }
  }

  enableValidation(email) {
    if (this.opportunity_useremail === undefined || this.opportunity_useremail.length === 0) {
      return true;
    }
    if ((/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i).test(email)) {
      this.toErrMsg = '';
    } else {
      this.toErrMsg = 'Enter a valid email';
      return true;
    }
  }
  ngOnDestroy(){
    this.subs.dispose();
  }
}
