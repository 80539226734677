export enum NodeProviders {
  'Cohesity Physical' = 'Cohesity Physical',
  Cohesity = 'Cohesity',
  'Cloud and Virtual' = 'Cloud and Virtual',
  Cisco = 'Cisco',
  HPE = 'HPE',
  Dell = 'Dell',
  Intel = 'Intel',
  Fujitsu = 'Fujitsu',
  Pure = 'Pure',
  SuperMicro = 'SuperMicro',
  Lenovo = 'Lenovo',
  IBM = 'IBM',
}

export interface Normal {
  name: NodeProviders;
  nodeList: string[];
}

export interface Compute {
  name: NodeProviders;
  nodeList: Array<Array<string>>;
}

export interface Robo {
  name: NodeProviders;
  nodeList: string[];
}

export interface NodeList {
  normal: Normal[];
  robo: Robo[];
  compute: Compute[];
}
