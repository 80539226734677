import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { HttpService } from './http.service';
import { RestService } from './rest.service';
import { ActiveXHRsListService } from './active-xhrs-list.services';
import {DataStoreInterceptor} from '../../interceptors/data-store-interceptor';

@NgModule({
    imports: [ HttpClientModule ],
    providers: [
        HttpService,
        RestService,
        ActiveXHRsListService,
      { provide: HTTP_INTERCEPTORS, useClass: DataStoreInterceptor, multi: true}
    ]
})
export class HttpServicesModule { }
