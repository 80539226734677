import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ActiveAjaxLoadersService, XHR_REQUEST_STATES } from './active-ajax-loaders.service';

@Injectable()
export class AjaxLoaderInterceptor implements HttpInterceptor {

    private loaderProp = 'ajax_loader';
    constructor(private activeAjaxLoadersService: ActiveAjaxLoadersService) { }

    intercept(request: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
        const loaderName = request.headers.get(this.loaderProp);
        const newReq = request.clone({
            headers: request.headers.delete(this.loaderProp)
        });
        this.updateLoaderState(loaderName, XHR_REQUEST_STATES.PROGRESS);

        return httpHandler.handle(newReq).do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.updateLoaderState(loaderName, XHR_REQUEST_STATES.SUCCESS, event);
            }
        }, (responseError: any) => {
            if (responseError instanceof HttpErrorResponse) {
                const responseErrorBody = responseError.error;
                let errorMessage = ('Unknown Error in API call. ' + loaderName);
                if (responseErrorBody && typeof (responseErrorBody.error_msg) === "string") {
                    errorMessage = responseErrorBody.error || responseErrorBody.message || JSON.stringify(responseErrorBody);
                }
                else {
                    let error_list = responseErrorBody.error_msg;
                    let error_string = "<ol>";
                    error_list.forEach(error => {
                        error_string += "<li>" + error + "</li>";
                    });
                    error_string += "</ol>";
                    errorMessage = responseErrorBody.error || responseErrorBody.message || JSON.stringify({ 'error_msg': error_string });
                }
                this.updateLoaderState(loaderName, XHR_REQUEST_STATES.ERROR, { error: errorMessage });
            }
        });
    }

    private updateLoaderState(loaderName, loaderState, data?) {
        if (loaderName) {
            this.activeAjaxLoadersService.update(loaderName, loaderState, data);
        }
    }
}
