import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export interface NodeSetListType {
  nodeName: string;
  configs: {
    config_name: string;
    slots: number;
    options: string;
    cluster_config: any;
    cloud_tier: boolean;
  }[];
  configurations: any;
  cluster_configs: any;
}

enum StateProperties {
  nodeSetList = "nodeSetList",
}

export interface AddClusterDataType {
  [StateProperties.nodeSetList]: NodeSetListType[];
}

@Injectable({
  providedIn: 'root'
})

export class AddClusterStoreService {
  private data = new BehaviorSubject<AddClusterDataType>({ nodeSetList: [] });
  data$: Observable<AddClusterDataType> = this.data.asObservable();

  constructor() {}

  private updateState(propertyToUpdated: StateProperties, valueToUpdate: any) {
    const updatedData: AddClusterDataType = Object.freeze(
      Object.assign(
        {},
        this.data,
        Object.freeze({ [propertyToUpdated]: valueToUpdate })
      )
    );
    this.data.next(updatedData);
  }

  public updateNodeSetList(newNodeSetList: NodeSetListType[]) {
    this.updateState(StateProperties.nodeSetList, newNodeSetList);
  }

  public getNodeSetList(){
    return this.data.value[StateProperties.nodeSetList]
  }
}
