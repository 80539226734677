import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { YEARS, NODE_COUNT_TABLE_MSG } from '../../constants/app.constants';

@Component({
  selector: 'app-recommended-node-details-table',
  templateUrl: './recommended-node-details-table.component.html',
  styleUrls: ['./recommended-node-details-table.component.css']
})
export class RecommendedNodeDetailsTableComponent implements OnInit, OnChanges {

  @Input() existingNodes: string[];
  @Input() selectedYear: string;
  @Input() existingNodeData;
  @Input() monthsForSelectedYear;
  @Input() opportunity;
  @Input() user;
  @Output() prefSelectedYear = new EventEmitter<any>();
  years: number[] = YEARS;
  nodeToggleBtn: boolean = false;
  nodeCountTableMsg: string = NODE_COUNT_TABLE_MSG;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(){}

  ngOnChanges(changes: SimpleChanges) {
   const { existingNodeData, existingNodes, ...rest } = changes;
   if(existingNodeData && existingNodeData.currentValue){
    this.existingNodeData = existingNodeData.currentValue;
    this.cdr.detectChanges();
   }
   if(existingNodes && existingNodes.currentValue){
    this.existingNodes = existingNodes.currentValue;
    this.cdr.detectChanges();
   }
    this.isImportedOpportunity();
    if (this.existingNodeData && this.existingNodes.length > 0) {
      this.hasValidNode(this.existingNodes[0]);
    }
  }

  nodeToggle() {
    this.nodeToggleBtn = !this.nodeToggleBtn;
  }

  prefYearChange(i: number) {
    this.prefSelectedYear.emit(i);
  }

  hasValidNode(nodeName: string) {
    return  this.existingNodeData.has(nodeName) && 
            this.existingNodeData.get(nodeName).has(this.monthsForSelectedYear);
  }

  isImportedOpportunity() {
    return ((!this.user
      || (this.user && Object.keys(this.user).length > 0 && this.user.role !== 'admin'))
      && this.opportunity['settings']['imported_opportunity'] !== undefined
      && this.opportunity['settings']['imported_opportunity'] === true);
  }

}
