import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataStoreService} from './data-store.service';
import {OppoDetailsService} from '../../app/opportunity-details/oppo-details.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [DataStoreService, OppoDetailsService],
  declarations: []
})
export class DataStoreModule {

  constructor(@Optional() @SkipSelf() parentModule: DataStoreModule) {

    if (parentModule) {
      throw new Error(`Cannot have multiple instances of Data Store running simultaneously.`);
    }

  }
}
