
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {

  transform(records: Array<any>, args?: any): any {
    if (args.property === undefined || args.property === '') {
      return records;
    }
    return records.sort(function (a, b) {
      if(typeof(a[args.property])==='number' && typeof(b[args.property])==='number'){
        if (a[args.property]<b[args.property]) {
          return -1 * args.direction;
        } else if (a[args.property]>b[args.property]) {
          return 1 * args.direction;
        } 
        return;

      }
      else{
        if (a[args.property].toLowerCase() < b[args.property].toLowerCase()) {
          return -1 * args.direction;
        } else if (a[args.property].toLowerCase() > b[args.property].toLowerCase()) {
          return 1 * args.direction;
        }
        return;
      }
    });
  }
}
