import {NodeList} from './node-list';
import {Opportunity, SiteListResponseObject} from './opportunity';

export enum StateProperties {
  selectedOpportunity = 'selectedOpportunity',
  nodes= 'nodes',
  siteList= 'siteList',
  locationModelList= 'locationModelList',
  constants= 'constants',
  resiliencyModelList= 'resiliencyModelList',
  selectedSite = 'selectedSite'
}

export enum LocalDataProperties {
  siteList= 'siteList',
  modalData = 'modalData'
}

export interface State {
  [StateProperties.selectedOpportunity]: Opportunity;
  [StateProperties.nodes]: NodeList;
  [StateProperties.siteList]: any[];
  [StateProperties.locationModelList]: any[];
  [StateProperties.constants]: any;
  [StateProperties.resiliencyModelList]: any[];
  [StateProperties.selectedSite]: SiteListResponseObject;
}
