import { Component, Input } from '@angular/core';
import { OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { ActiveAjaxLoadersService, XHR_REQUEST_STATES } from '../active-ajax-loaders.service';
import { AjaxLoaderOptions } from '../ajax-loader.options';

@Component({
  selector: 'app-ajax-loader',
  templateUrl: './ajax-loader.component.html',
  styleUrls: ['./ajax-loader.component.css']
})
export class AjaxLoaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input('loaderId') loaderId: string;
  @Input('config') config?: AjaxLoaderOptions;

  defaultConfig: AjaxLoaderOptions = new AjaxLoaderOptions();
  canShow = false;
  currentState: any;
  statusMessage: string;
  requestStates = XHR_REQUEST_STATES;
  private subscription: any;
  oppShared: boolean;


  constructor(private activeAjaxLoadersService: ActiveAjaxLoadersService) { }

  close() {
    this.canShow = false;
  }

  private init() {
    this.subscription = this.activeAjaxLoadersService.onxhrListChange.subscribe((evtData) => {
      if (this.loaderId === evtData.loaderId) {
        this.currentState = evtData.reqCurrentState;
          this.update(evtData);
      }
    });
  }

  private update(evtData) {
    switch (this.currentState) {
      case XHR_REQUEST_STATES.PROGRESS: {
        this.canShow = !(this.config.hideOnProgress === true);
        break;
      }
      case XHR_REQUEST_STATES.ERROR: {
        this.canShow = !(this.config.hideOnError === true);
        this.statusMessage = this.getStatus(evtData.response);
        break;
      }
      case XHR_REQUEST_STATES.SUCCESS: {
        this.canShow = !(this.config.hideOnSuccess === true);
        this.statusMessage = this.getSuccessStatus(evtData.loaderId ,evtData.response);
        break;
      }
      case XHR_REQUEST_STATES.CANCELED: {
        this.canShow = !(this.config.hideOnCanceled === true);
        this.statusMessage = this.getStatus(evtData.response);
        break;
      }
      default: {
        this.canShow = false;
      }
    }
  }

  private getStatus(xhrStatusData) {
    let status = '';
    if (xhrStatusData && typeof xhrStatusData === 'object') {
      status = JSON.parse(xhrStatusData.error) || xhrStatusData.detail || '';
      if(status['error_msg'] && typeof(status['error_msg'])!=="string"){
        status['error_msg']=status['error_msg'].join('\n');
      }
    }
    return status;
  }

  private getSuccessStatus(loaderId, xhrStatusData){
    let status = '';
    if (xhrStatusData && typeof xhrStatusData === 'object' && loaderId === 'CREATE_OPPORTUNITY') {
      if(xhrStatusData.body.status !== undefined && xhrStatusData.body.status === 'success' && xhrStatusData.url.includes("clone")){
        this.canShow = true;
        if(xhrStatusData.statusText === 'OK'){
          status = "The WST sizing was successfully shared, please hit ok to continue";
          this.oppShared = true;
        }
        if(xhrStatusData.statusText === 'Created'){
          status = "Opportunity cloned successfully.";
          this.oppShared = false;
        }
      }
    }
    return status;
  }


  ngOnChanges(changes: SimpleChanges) {
     this.config = {
       ...this.defaultConfig,
       ...this.config
     };
     this.update({});
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}





