import {Injectable, EventEmitter} from '@angular/core';
import {Http} from '@angular/http';
import {HttpService} from 'src/shared/services/http/http.service';
import {RestService} from 'src/shared/services/http/rest.service';
import {BehaviorSubject} from 'rxjs';
import {NodeList} from '../../models/node-list';
import {environment} from "../../../environments/environment";
import { COHESITY, IBM } from '../constants/app.constants';

export interface OpportunityDataType {
  id: number;
  name: string;
  tags: string[];
  sizing_summary: string;
  customer_name: string;
  last_modified_by: string;
  created_by: string;
  created_date: string;
  updated_date: string;
  edit_lock: boolean;
  username: string;
  version: string;
  usecase: string;
  sfdc_id: string;
  opp_type: number;
  policy_list: string[]; 
  def_tco_settings: Record<string, string>; 
  cloud_policy_list: string[]; 
  snapshot_policies: string[]; 
  settings: {
    resized: boolean;
    commercial: boolean;
    node_sizing: boolean;
    sizing_version: string;
    imported_opportunity: boolean;
  };
  sfdc_linked_id: string;
} 

@Injectable()
export class ConfigService extends RestService {
  url = environment.http.baseUrl;
  constants: any;
  opportunityData : OpportunityDataType | {} = {};
  tcoData = {};
  localWarn = {};
  siteNodeMap = {};
  prePolicyList = [];
  tcoStorage = {};
  resiliencyList = [];
  locationList = [];
  oppoList : OpportunityDataType[] = [];
  nodeList: NodeList = {compute: [], normal: [], robo: []};
  version: string;
  userdetail: any;
  useCase = [];
  unitOfMeasure = '';
  opp_usecase = 'node';
  split_version;
  userDetails: BehaviorSubject<any>;
  changedOppList: any = new EventEmitter();
  user_detail: any;
  companyName = environment.isCohesityInstance ? COHESITY : IBM ;

  constructor(
    public http: Http,
    httpService: HttpService,
  ) {
    super(httpService);
    this.userDetails = new BehaviorSubject('');
  }

  getPrepolicyListData() {
    return this.prePolicyList;
  }

  getResiliencyListData() {
    return this.resiliencyList;
  }

  getLocationListData() {
    return this.locationList;
  }

  getNodePrefListData(): NodeList {
    return this.nodeList || {normal: [], robo: [], compute: []};
  }

  getUseCaseListData() {
    return this.useCase;
  }

  getLocalwarn() {
    return this.localWarn;
  }

  setLocalWarn(localWarn) {
    this.localWarn = localWarn;
  }

  gettcoStorage() {
    return this.tcoStorage;
  }

  settcoStorage(tcoStorage) {
    this.tcoStorage = tcoStorage;
  }

  setPrepolicyListData(policyList) {
    this.prePolicyList = policyList;
  }

  setResiliencyList(resList) {
    this.resiliencyList = resList;
  }

  setLocationList(locList) {
    this.locationList = locList;
  }

  setNodePrefListData(nodeList) {
    this.nodeList = nodeList || {normal: [], robo: [], compute: []};
    if (!this.nodeList['compute']) {
      this.nodeList['compute'] = [];
    }
    // this.nodeList['compute'] = nodeList.normal.filter((e, i) => i % 2 === 0);
  }

  setUseCaseTypeListData(useCase) {
    this.useCase = useCase;
  }

  getOpportunityData() {
    return this.opportunityData;
  }

  setOpportunityData(opportunity) {
    this.opportunityData = opportunity;
  }

  setTcoData(tcoData) { 
    this.tcoData = tcoData;
  }

  getTcoData() {
    return this.tcoData;
  }

  getSiteNodeMap() {
    return this.siteNodeMap;
  }

  setSiteNodeMap(siteNodeMap) {
    this.siteNodeMap = siteNodeMap;
  }

  getConstants() {
    return this.http.get('../../../assets/default_constant.json')
  }
  setConstants(constants){
    this.constants = constants;
  }

  getVersion() {
    const versionUrl = this.url + '/envsetup/version';
    return this.http.get(versionUrl);
  }

  setUnitOfMeasure(opp_version) {
    const opportunityVersion = opp_version.split('.');
    this.split_version = opportunityVersion;
    if ( ( parseInt(opportunityVersion[0], 10) >= 4 && (parseInt(opportunityVersion[1], 10) >= 5) ) || parseInt(opportunityVersion[0], 10) >= 5) {
      this.unitOfMeasure = 'TB';
    } else {
      this.unitOfMeasure = 'TiB';
    }
  }


  verifyVersion(version?) {
    let spiltVersion;
    if (version !== undefined) {
      spiltVersion = version.split('.');
    } else {
      spiltVersion = this.split_version;
    }
    if (spiltVersion !== undefined) {
      if ((parseInt(spiltVersion[0], 10) > 4) || (parseInt(spiltVersion[0], 10) === 4 && (parseInt(spiltVersion[1], 10) >= 7))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setOpportunityUsecase(usecase) {
    this.opp_usecase = usecase;
  }

  fetchOpportunityUsecase() {
    return this.opp_usecase;
  }

  fetchUnitOfMeasure() {
    if (this.unitOfMeasure.length === 0 && this.opportunityData['version'] !== undefined) {
      this.setUnitOfMeasure(this.opportunityData['version']);
    }
    return this.unitOfMeasure;
  }

  getUserDetails() {
    const userDetUrl = this.url + '/envsetup/userdetail';
    return this.http.get(userDetUrl);
  }

  putUserDetails(payload) {
    this.baseUrl = this.url + '/envsetup/userdetail';
    return this.update('', '', payload);
  }

  fetchUserDetails() {
    return this.userdetail;
  }

  setUserDetails(userDet) {
    this.userdetail = userDet;
  }

  changeInUserDetail(userDetl) {
    this.userDetails.next(userDetl);
  }

  fetchVersion() {
    return this.version;
  }

  setVersion(version) {
    this.version = version;
  }

  setOpportunityList(oppoList) {
    this.oppoList = oppoList;
  }

  getOpportunityList() {
    return this.oppoList;
  }

  fetchConstants() {
    return this.constants;
  }

  getResiliencyList() {
    const resiliencyUrl = this.url + '/envsetup/resiliency';
    return this.http.get(resiliencyUrl);

  }

  getLocationList() {
    const locationUrl = this.url + '/envsetup/location';
    return this.http.get(locationUrl);
  }

  getPrePolicyList() {
    const prePolicyUrl = this.url + '/envsetup/prepolicy';
    return this.http.get(prePolicyUrl);
  }

  getNodePrefList() {
    const nodesUrl = this.url + '/envsetup/nodes';
    return this.http.get(nodesUrl);
  }

  getUseCaseTypeList() {
    const workloadsUrl = this.url + '/envsetup/workloads';
    return this.http.get(workloadsUrl);
  }

  oppListCHange() {
    this.changedOppList.emit(this.oppoList);
  }
}
