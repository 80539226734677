import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SearchPipe } from './pipes/searchByKey.pipe';
import { OrderByPipe } from './pipes/orderby.pipe';
import { UniquePipe } from './pipes/unique.pipe';
import { MinValidatorDirective } from './directives/min-validator.directive';
import { MaxValidatorDirective } from './directives/max-validator.directive';
import { UtilsService } from './services/utils.service';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CeConfigurationTable } from '../opportunities-container/ce-configuration-table/ce-configuration-table.component';
import { RecommendedNodeDetailsTableComponent } from './components/recommended-node-details-table/recommended-node-details-table.component';
import { CeilPipe } from './pipes/ceil.pipe';
import { LicenseCalculationDirective } from './directives/license-calculations.directive';

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */
@NgModule({
    imports: [
        CommonModule, 
        RouterModule, 
        FormsModule, 
        ReactiveFormsModule, 
        TooltipModule.forRoot()
    ],
    declarations: [
        SearchPipe,
        OrderByPipe,
        UniquePipe,
        CeilPipe,
        MinValidatorDirective,
        MaxValidatorDirective,
        LicenseCalculationDirective,
        ToggleSwitchComponent,
        CeConfigurationTable,
        RecommendedNodeDetailsTableComponent
    ],
    exports: [
        SearchPipe,
        OrderByPipe,
        UniquePipe,
        CeilPipe,
        MinValidatorDirective,
        MaxValidatorDirective,
        LicenseCalculationDirective,
        ToggleSwitchComponent,
        CeConfigurationTable,
        RecommendedNodeDetailsTableComponent
    ],
  providers: [UtilsService, DecimalPipe]
})

export class SharedModule {}
