import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import {
  AddClusterStoreService,
  NodeSetListType,
} from "src/app/modules/add-cluster-v2/add-cluster-v2-modal/add-cluster-v2-data-store.service";

@Component({
  selector: "ce-configuration-table",
  templateUrl: "./ce-configuration-table.component.html",
  styleUrls: ["../opportunities-modal/opportunities-modal.component.css",'./ce-configuration-table.component.css'],
})
export class CeConfigurationTable implements OnInit {
  @Input() nodeSetListProp: NodeSetListType[] | null = null;
  @Output() deleteFieldValue = new EventEmitter<any>();
  @Output() addFieldValue = new EventEmitter<any>();

  nodeSetList = [];
  constructor(public ds: AddClusterStoreService) {}

  addFieldValueCaller(node,nodeIndex:number) {
    this.addFieldValue.emit({node,nodeIndex});
  }

  deleteFieldValueCaller(node: number, config: number) {
    this.deleteFieldValue.emit({ node, config });
  }
  
  ngOnInit() {
    if (this.nodeSetListProp !== null) {
      this.nodeSetList = this.nodeSetListProp;
    } else {
      this.ds.data$.subscribe((value) => {
        this.nodeSetList = value.nodeSetList;
      });
    }
  }
}
