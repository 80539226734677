import {Component, AfterViewInit, Output, EventEmitter, OnDestroy, OnInit,Renderer2} from '@angular/core';

import {AssignOppModalComponent} from './assign-opp-modal/assign-opp-modal.component';
import {TransferOppModalComponent} from './transfer-opp-modal/transfer-opp-modal.component';
import {ViewAsModalComponent} from './view-as-modal/view-as-modal.component';

import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap';

import {ConfigService} from '../shared/services/config.service';
import {HeaderService} from './header.service';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {SubscriptionsContainer} from '../shared/subscriptions-container';
import {CookieService} from 'ngx-cookie-service';
import {ConfirmModalComponent} from '../ui-components/confirm-modal/confirm-modal.component';
import {mergeMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SimpleSizeDatastoreService} from '../commercial-sizer/services/simple-size-datastore.service';
import { DataStoreService } from 'src/shared/data-store/data-store.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() changedOppList = new EventEmitter<any>();
  version: string;
  headerString: string;
  userDetail: any;
  nodePrefList: any;
  resiliencyList: any;
  locationList: any;
  prePolicyList: any;
  bsModalRef: BsModalRef;
  opportunityList: any;
  errMsg: any;
  isBetaServer = false;
  displayViewAsEmail: boolean;
  showUserDetails = false;
  inOppPage: any;
  subs = new SubscriptionsContainer();
  emailId: string;
  environment = environment;
  opportunityId:string|null=null;
  showTextArea = false;
  isSubmitClicked= false;
  responseBugText = '';
  headerLogo: string = "";

  constructor(public configService: ConfigService,
              private ds: DataStoreService,
              private headerService: HeaderService,
              private titleService: Title,
              private bsModalService: BsModalService,
              private router: Router,
              private cookieService: CookieService,
              public  simpleSizerDS: SimpleSizeDatastoreService,
              private renderer: Renderer2 ) {

    this.configService.userDetails.subscribe(user_detail => {
      this.userDetail = user_detail;
      if ((!this.cookieService.check('wstVersion') || this.cookieService.get('wstVersion') === this.version) && this.userDetail !== undefined && this.userDetail.ENABLE_EULA !== undefined && this.userDetail.ENABLE_EULA) {
        this.eulaPopup();
      }
      if (this.userDetail) {
        this.configService.setUserDetails(this.userDetail);
        this.simpleSizerDS.onSimpleSizerActivated();
      }
      this.showUserDetails = this.userDetail.viewpoint;
      this.simpleSizerDS.onSimpleSizerActivated();
    });
    this.setOpportunityId()
  }

  ngOnInit() {
    this.simpleSizerDS.isSimpleSizer$.subscribe(currentValue => {
      this.updateHeaderForCommercialSizer();
    });
  }

  eulaPopup() {
    if (this.userDetail.settings !== undefined && this.userDetail.settings.eula_datetime !== undefined) {
      const self = this;
      const month = [1, 4, 7, 10];
      const initialState = {
        title: 'EULA',
        message: 'Accept EULA?',
        okBtnMsg: 'Agree',
        hideClose: true
      };
      const today = (new Date()).toLocaleDateString('en-US');
      if (self.userDetail.settings.eula_datetime.length === 0 || ((parseInt(today.split('/')[1]) === 1) && (month.indexOf(parseInt(today.split('/')[0])) !== -1) && (today !== this.userDetail.settings.eula_datetime))) {
        self.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
          ignoreBackdropClick: true,
          class: 'position-center',
          keyboard: false,
          initialState
        });
        self.subs.add = self.bsModalRef.content.onClose.subscribe(modalData => {
          if (modalData !== undefined) {
            const payload = JSON.parse(JSON.stringify(self.userDetail));
            payload.settings.eula_datetime = today;
            delete payload.ENABLE_EULA;
            self.configService.putUserDetails(payload).subscribe(res => {
              self.userDetail = JSON.parse(res['_body']);
              self.configService.setUserDetails(self.userDetail);
              this.simpleSizerDS.onSimpleSizerActivated();
            });
          }
        }, error => {
          if ((!this.cookieService.check('wstVersion') || this.cookieService.get('wstVersion') === this.version) && this.userDetail !== undefined && this.userDetail.ENABLE_EULA !== undefined && this.userDetail.ENABLE_EULA) {
            this.eulaPopup();
          }
        });
      }
    }
  }

  ngAfterViewInit() {

    if ((window.location.hash).includes('opportunities')) {
      this.inOppPage = true;
    } else {
      this.inOppPage = false;
    }
    const host = window.location.host;
    if (host !== undefined) {
      if ((host).includes('34.71.27.4')) {
        this.isBetaServer = true;
      }
    }
    this.headerString = environment.headerString;
    if (environment.isIBMInstance) {
      this.emailId = 'ibm_wst_support@cohesity.com';
      this.headerLogo = "assets/images/ibm-defender-white-logo.svg"
    } else {
      this.emailId = 'wst_support@cohesity.com';
      this.headerLogo = "assets/images/cohesity-white-logo.svg"
    }
    this.subs.add = this.configService.getVersion()
      .subscribe((response) => {
        this.version = response['_body'];
        this.configService.setVersion(this.version);
        this.titleService.setTitle('Cohesity WST ' + this.version);
        this.checkForCookie();
      });
    this.subs.add = this.configService.getPrePolicyList().subscribe((response) => {
      this.prePolicyList = JSON.parse(response['_body']);
      this.prePolicyList.map(pol => pol.editDisable = true);
      this.configService.setPrepolicyListData(this.prePolicyList);
    });
    this.subs.add = this.configService.getResiliencyList().finally(() => {
    }).subscribe((response) => {
      let resList = [];
      const response_body = JSON.parse(response['_body']);
      for (const key in response_body) {
        if (response_body.hasOwnProperty(key)) {
          const resDat = {
            name: key,
            resList: []
          };
          for (const reskey in response_body[key]) {
            if (response_body[key].hasOwnProperty(reskey)) {
              resDat.resList.push({
                'res': reskey,
                'node': response_body[key][reskey]
              });
            }
          }
          resDat.resList = resDat.resList.sort(function (a, b) {
            if (a['res'].toLowerCase() < b['res'].toLowerCase()) {
              return -1;
            } else if (a['res'].toLowerCase() > b['res'].toLowerCase()) {
              return 1;
            } else {
              return;
            }
          });
          resList.push(resDat);
          resList = resList.sort(function (a, b) {
            if (a['name'].toLowerCase() < b['name'].toLowerCase()) {
              return -1;
            } else if (a['name'].toLowerCase() > b['name'].toLowerCase()) {
              return 1;
            } else {
              return;
            }
          });
        }
      }
      this.resiliencyList = resList;
      this.configService.setResiliencyList(this.resiliencyList);
      this.ds.setResiliencyList(this.resiliencyList);
    }, error => {
    });
    this.subs.add = this.configService.getLocationList().finally(() => {
    }).subscribe((response) => {
      let locList = [];
      const response_body = JSON.parse(response['_body']);
      for (const key in response_body) {
        const locDat = {
          label: key,
          cost: response_body[key]
        };
        if (response_body.hasOwnProperty(key)) {
          locList.push(locDat);
          locList = locList.sort(function (a, b) {
            if (a['label'].toLowerCase() < b['label'].toLowerCase()) {
              return -1;
            } else if (a['label'].toLowerCase() > b['label'].toLowerCase()) {
              return 1;
            } else {
              return;
            }
          });
        }
      }
      this.locationList = locList;
      this.configService.setLocationList(this.locationList);
      this.ds.setLocationModelList(this.locationList)
    }, error => {
    });
    this.subs.add = this.configService.getUseCaseTypeList().finally(() => {
    }).subscribe((response) => {
      const useCasePref = [];
      const response_body = JSON.parse(response['_body']);
      response_body.map(useCase => {
        const data = {
          name: useCase[0][0],
          key: useCase[0][1],
          visibility: useCase[0][2],
          subType: []
        };
        useCase[1].map(subData => {
          data.subType.push({
            name: subData[0],
            key: subData[1],
            visibility: subData[2]
          });
        });
        useCasePref.push(data);
      });
      this.configService.setUseCaseTypeListData(useCasePref);
    }, error => {
    });


  }

  analyticsPage() {
    this.router.navigate(['/analytics/']);
  }

  onOpportunityAssign() {
    const self = this;
    this.opportunityList = this.configService.opportunityData;
    const initialState = {
      'opportunityList': this.opportunityList
    };
    // this.headerService.setOppList(this.opportunityList);
    this.bsModalRef = this.bsModalService.show(AssignOppModalComponent, {
      ignoreBackdropClick: true,
      keyboard: false,
      initialState
    });
    this.subs.add = this.bsModalRef.content.onClose.subscribe(modalData => {
      if (modalData !== undefined) {
        this.changedOppList.emit(modalData);
      }
    });

  }

  onOpportunityTranfer() {
    this.bsModalRef = this.bsModalService.show(TransferOppModalComponent, {ignoreBackdropClick: true, keyboard: false});
    this.subs.add = this.bsModalRef.content.onClose.subscribe(modalData => {
      if (modalData !== undefined) {
        this.changedOppList.emit(modalData);
      }
    }, error => {
      this.errMsg = error.error['error_msg'];
    });
  }

  onViewAs() {
    this.subs.add = this.headerService.getAllEmailIds().subscribe(response => {
      if (response !== undefined) {
        const initialState = {
          'emailIds': response.email === undefined ? [] : response.email
        };
        this.bsModalRef = this.bsModalService.show(ViewAsModalComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          initialState
        });
        this.subs.add = this.bsModalRef.content.onClose.subscribe(modalData => {
          this.showUserDetails = false;
          if (modalData !== undefined) {
            this.userDetail = modalData;
            this.changedOppList.emit({
              'user': this.userDetail
            });
            this.displayViewAsEmail = true;
            this.showUserDetails = this.userDetail.viewpoint;
          }
        }, error => {
          this.errMsg = error.error['error_msg'];
        });
      }
    });
  }

  onViewAsAdmin() {
    this.displayViewAsEmail = false;
    const payload = {
      'viewas': 'admin'
    };
    this.subs.add = this.headerService.viewAs(payload).pipe(mergeMap(res => {
      return this.configService.getUserDetails();
    })).subscribe(user => {
      this.userDetail = JSON.parse(user['_body']);
      this.configService.setUserDetails(this.userDetail);
      this.simpleSizerDS.onSimpleSizerActivated();
      this.changedOppList.emit({
        'user': {
          'role': 'admin'
        }
      });
      this.showUserDetails = false;
    }, err => {
    });
  }

  nodeInformation() {
    this.router.navigate(['/nodeInformation/']);
  }

  onIconClick() {
    if ((window.location.hash).includes('opportunities')) {
      this.inOppPage = true;
    } else {
      this.inOppPage = false;
    }
    this.showTextArea =true ;
  }

  checkForCookie() {
    if (!this.cookieService.check('wstVersion')) {
      this.cookieService.set('wstVersion', this.version, null, '/', null, null, null);
    } else {
      if (this.cookieService.get('wstVersion') !== this.version) {
        const tempWstVersion = this.cookieService.get('wstVersion');
        this.cookieService.delete('wstVersion', '/index.html');
        if (!this.cookieService.check('wstVersion')) {
          this.cookieService.set('wstVersion', tempWstVersion, null, '/', null, null, null);
        }
        const initialState = {
          title: 'CLEAR BROWSER CACHE',
          message: 'New WST version has been deployed. Please do a hard refresh by pressing Ctrl/Cmd + Shift + R.',
          okBtnMsg: 'OK',
          hideClose: true
        };
        this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, {
          ignoreBackdropClick: true,
          class: 'position-center',
          keyboard: false,
          initialState
        });
        this.subs.add = this.bsModalRef.content.onClose.subscribe(modalData => {
          if (modalData !== undefined) {
            this.cookieService.set('wstVersion', this.version, null, '/', null, null, null);
            if (this.userDetail !== undefined && this.userDetail.ENABLE_EULA !== undefined && this.userDetail.ENABLE_EULA) {
              this.eulaPopup();
            }
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.subs.dispose();
  }

  private copyWSTIDToClipboard() {
    const input = document.createElement('input');
    input.setAttribute('value', this.opportunityId);
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
  }

  private updateHeaderForCommercialSizer() {

    if (this.simpleSizerDS.state.isSimpleSizer === true) {
      this.headerString = environment.headerStringForSimpleSizer;
      this.emailId = `simplesizer@cohesity.com`;
    }
  }

  logoClickHandler(){
    if(this.simpleSizerDS.state.isSimpleSizer === true){
      this.router.navigate(['/all-opportunities'])
      return;
    }
    this.router.navigate(['/opportunities'])
  }
  setOpportunityId(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const hashes = window.location.hash.split('/');
        const isNumber = !isNaN(Number(hashes[hashes.length - 1]))
        if(isNumber){
          this.opportunityId =hashes[hashes.length - 1];
          return;
        }
        this.opportunityId=null;
      }
    })
  }
  onSubmit(form :any) {
    if(form.valid){
    this.isSubmitClicked= true;
    const body =`Hi, Below are the list of issues faced while using Cohesity Sizing Tool.

Opp Id:
${this.opportunityId ? this.opportunityId:'N/A'}

Version: 
${this.version}
    
Description: 
${this.responseBugText}`;
    const subject = `Cohesity WST ${this.version} Bug Report - Opp Id: ${this.opportunityId ? this.opportunityId:'N/A'}`;
    const mailtoLink = `mailto:${this.emailId}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    // Create a temporary anchor element to trigger the mailto link
    const tempLink = document.createElement('a');
    tempLink.href = mailtoLink;
    tempLink.click();
    setTimeout(() => { //This settimeout is time for textarea to refresh. It is also time thank-you-message stays
      this.showTextArea = false;
      this.isSubmitClicked= false;
      this.responseBugText = '';
      document.body.removeChild(tempLink);
    }, 11000);}
  }
}
