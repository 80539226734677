import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs-compat/Subject';
export interface ConfirmModel {
  title: string;
  message: string;
}
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent {

  public onClose: Subject<boolean>;
  title: string;
  message: string;
  okBtnMsg: string;
  hideClose: boolean;
  constructor(public bsModalRef: BsModalRef) {
    this.onClose = new Subject();
  }
  confirm() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  close() {
    this.onClose.next();
    this.bsModalRef.hide();
  }


}
