import { Injectable } from '@angular/core';
import { lLM } from 'src/app/models/gaia.model';
import { SiteSettings, Node } from 'src/app/models/opportunity';
import * as GaiaConstants from '../constants/gaia.constants';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  // TODO: This is an N^2 solution, needs to be made better, but needed given the way the codebase currently exists.
  buildComputeNodePrefList(allNodesList, currentOpportunityNodeSet: string[]): any[] {
    const temp: {name: 'string', nodeList: string[]}[] = [];

    for (let i = 0; i < allNodesList.length; i++) {

      for (let j = 0; j < allNodesList[i].nodeList.length; j++) {

        const exists = currentOpportunityNodeSet.includes(allNodesList[i].nodeList[j]);

        if (exists === true) {
          const hasName = temp.findIndex(elm => elm.name === allNodesList[i].name);

          if (hasName > -1) {
            temp[hasName].nodeList.push(allNodesList[i].nodeList[j]);
          } else {
            temp.push({
              name: allNodesList[i].name,
              nodeList: [allNodesList[i].nodeList[j]]
            });
          }


        }


      }


    }

    return temp;

  }

  public static getNodeDescription(node: Node, nodeName: string, siteSettings: SiteSettings): string {
    if(node.node_description) {
      return node.node_description;
    }

    /**
     * For older data in which node_descripion field is not present
     * we get node description using node attributes such as cpu, ram, etc.
     */

    let nodeDescription: string = `${node.cpu_slots} x ${node.cpu_description} | ${node.ram_slots} x ${node.ram_description}`;

    /**
     * PXG is a compute only platform.
     * The actual storage is provided by the Pure Storage platform.
     * PXG(Compute) + PureFlashArray(Storage) together makes up the data protection system.
     * PXG runs Cohesity and provides the software.
     * PureFlashArray runs Pure software and provides the fast SSD storage.
     */
    const PURE_STORAGE_NODE: Set<string> = new Set<string>([
      'PXG1',
      'PXG2'
    ]);

    if(!PURE_STORAGE_NODE.has(nodeName)) {
      if(!((nodeName && nodeName.includes('(AF)')) || (siteSettings && siteSettings.is_ngce))) {
        nodeDescription += ` | ${node.hdd_slots} x ${node.hdd_description}`;
      }
      nodeDescription += ` | ${node.ssd_slots} x ${node.ssd_description}`;
      if(node.available_storage_capacity) {
        nodeDescription += ` (Available Storage Capacity: ${(Math.round(node.available_storage_capacity * 100) / 100).toFixed(2)} TB`;
        if(siteSettings && siteSettings.is_ngce) {
          nodeDescription += ' Object Storage - Hot Blob'
        }
        nodeDescription += ')'
      }
      if(node.available_metadata_capacity) {
        nodeDescription += `; (Metadata Capacity: ${(Math.round(node.available_metadata_capacity * 100) / 100).toFixed(2)} TB)`;
      }
    }

    if(node.instance_type) {
      nodeDescription += ` | ${node.instance_type}`;
    }

    return nodeDescription;
  }

  public static hideTableRow(rowName: string): boolean {
    switch(rowName) {
      case 'Storage':
      case 'Required Nodes':
      case 'Required Storage Nodes':
      case 'Storage Required with Retention excluding Resiliency (BETB on Cohesity) (TB)':
      case 'Storage Required with Retention excluding Resiliency (BETB on Cohesity) (TiB)':
      case 'Storage Required with Retention excluding Resiliency (BETB on Cohesity) (Avg TB)': {
        return true;
      }
    }
    return false;
  }

  public static getLLMs(): Array<lLM> {
    return [
      {
        name: GaiaConstants.GPT4_0,
        value: GaiaConstants.GPT4_0_VALUE
      },
      {
        name: GaiaConstants.GPT3_5_TURBO,
        value: GaiaConstants.GPT3_5_TURBO_VALUE
      },
      {
        name: GaiaConstants.BOTH_GPT4_0_GPT3_5_TURBO,
        value: GaiaConstants.BOTH_GPT4_0_GPT3_5_TURBO_VALUE
      }
    ];
  }
}
