import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ISubscription } from 'rxjs/Subscription';
import { ActiveXHRsListService } from '../services/http/active-xhrs-list.services';

export enum XHR_REQUEST_STATES {
    PROGRESS,
    SUCCESS,
    ERROR,
    CANCELED
}

interface XHRStatus {
    id: string;
    status: XHR_REQUEST_STATES;
}
let count: number = 0;
@Injectable()
export class ActiveAjaxLoadersService {

    public onxhrListChange: EventEmitter<any> = new EventEmitter();
    private activeLoaders: Object = {}; /* Key as the request ID & values as the request state */
    public name;
    constructor(private activeXHRsListService: ActiveXHRsListService) {
        this.activeXHRsListService.events.cancelAll.subscribe(() => {
            const eventData = { 'error': 'Request is Canceled' };
            Object.keys(this.activeLoaders).forEach(xhrLoaderId => {
                this.emitXHRStateChange(xhrLoaderId, XHR_REQUEST_STATES.CANCELED, eventData);
                this.removeLoader(xhrLoaderId);
            });
        });
        this.name = 'count_' + count++;
    }

    update(loaderId, reqCurrentState, response) {
        const loaderState = this.activeLoaders[loaderId];
        if (loaderState !== reqCurrentState) {
            this.activeLoaders[loaderId] = reqCurrentState;
            // emitting event on every update
            this.emitXHRStateChange(loaderId, reqCurrentState, response);

            // deleting the loader from the list
            if (loaderState === XHR_REQUEST_STATES.ERROR || loaderState === XHR_REQUEST_STATES.SUCCESS) {
                this.removeLoader(loaderId);
            }
        }
    }

    private removeLoader(xhrLoaderId) {
        delete this.activeLoaders[xhrLoaderId];
    }

    private emitXHRStateChange(xhrLoaderId, xhrState, eventData) {
        this.onxhrListChange.emit({
            loaderId: xhrLoaderId,
            reqCurrentState: xhrState,
            response: eventData
        });
    }

}
