import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, HttpParams, HttpHandler } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ActiveXHRsListService } from './active-xhrs-list.services';

@Injectable()
export class HttpService {

  private defaultHeaders = {};

  constructor(private httpClient: HttpClient, private activeXHRsListService: ActiveXHRsListService) { }

  private getHeaders(headersConfig) {
    let httpHeaders = new HttpHeaders({ headers: headersConfig });
    Object.keys(headersConfig).forEach(headerName => {
      httpHeaders = httpHeaders.append(headerName, headersConfig[headerName]);
    });
    return httpHeaders;
  }

  private getParams(paramsConfig = {}) {
    let httpParams = new HttpParams();
    Object.keys(paramsConfig).forEach(function (key) {
      httpParams = httpParams.append(key, paramsConfig[key]);
    });
    return httpParams;
  }

  private removedNullUndefinedData(data) {
    if (!data) {
      return;
    }
    Object.keys(data).forEach(dataProp => {
      if (data[dataProp] === null || data[dataProp] === undefined) {
        delete data[dataProp];
      }
    });
  }

  processRequest(config): Observable<any> {
    config.headers = {
      ...this.defaultHeaders,
      ...config.headers
    };
    this.removedNullUndefinedData(config);
    this.removedNullUndefinedData(config.headerName);

    config.headers = this.getHeaders(config.headers);
    config.params = this.getParams(config.params);

    return Observable.create((observer) => {
      const subscription = this.httpClient.request(config.method, config.url, config)
        .catch((errorResponse: HttpResponse<any>) => {
          this.logErrors(errorResponse);
          observer.error(errorResponse);
          observer.complete();
          return Observable.throw(errorResponse);
        })
        .finally(() => {
          this.activeXHRsListService.delete(subscription);
        })
        .subscribe((response) => {
          observer.next(response);
          observer.complete();
        });
      this.activeXHRsListService.add(subscription);
    });
  }


  private logErrors(errorResponse) {
    // In a real world app, you might use a remote logging infrastructure
    // console.warn('Global Error Handler for AJAX Calls');
  }

}



