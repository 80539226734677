import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.css']
})
export class ToggleSwitchComponent implements OnInit {

  @Input('leftLabel') leftLabel = 'left';
  @Input('rightLabel') rightLabel = 'right';
  @Input('model') model = false;

  @Output('toggle') toggle = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onFocus(event) {
    event.currentTarget.parentNode.classList.add('focus');
  }

  onBlur(event) {
    event.currentTarget.parentNode.classList.remove('focus');
  }

  onValueChanged(event: Event) {
    event.preventDefault();
    this.toggle.emit({isActive: (<HTMLInputElement>event.target).checked});
  }

}
