import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http/http.service';
import { RestService } from '../../shared/services/http/rest.service';
import {environment} from '../../environments/environment';

@Injectable()
export class HeaderService extends RestService{

  queryName = 'CHANGEOPP';
  ajaxLoaderEnabled = true;
  url = environment.http.baseUrl;

  constructor(httpService: HttpService) {
    super(httpService);
  }

  asignOpportunity(payload) {
    this.baseUrl = this.url + '/assignuser/';
    return this.update('', '', payload);
  }

  transferOpportunity(payload){
    this.baseUrl = this.url + '/transferopportunity/';
    return this.save('', '', payload);
  }

  nodeInfo() {
    this.baseUrl = this.url + '/nodeinfo/';
    return this.query();
  }

  viewAs(payload) {
    this.baseUrl = this.url + '/viewasuser/';
    return this.update('', '', payload);
  }

  getAllEmailIds() {
    this.baseUrl = this.url + '/viewasuser/';
    return this.query();
  }
}
