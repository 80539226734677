const isIBMInstance = false;
const isCohesityInstance = true;
const isSimpleSizer = true;

export const environment = {
  production: true,
  http: {
    baseUrl: '',
  },
  headerString: 'WEB SIZING TOOL',
  headerStringForSimpleSizer : 'SIMPLE-SIZER',
  disable_console: true,
  enableDataHawk: isCohesityInstance && !isIBMInstance,
  isArchiveToTapeEnabled: isCohesityInstance && !isIBMInstance,
  isSmartFilesSizerEnabled: isCohesityInstance && !isIBMInstance,
  isCCSEnabled: isCohesityInstance && !isIBMInstance,
  isFortKnoxVaultingEnabled: isCohesityInstance && !isIBMInstance,
  isBundleLicensingEnabled: isCohesityInstance && !isIBMInstance,
  isIBMInstance: isIBMInstance,
  isCohesityInstance: isCohesityInstance,
  enableMetadataSizing: true,
  enableFKCAv2: false,
  enableHeterogeneousClusters: true,
  enableCustomDataReduction: true,
  enableClusterWideLimit: false,
  isSimpleSizer,
};
