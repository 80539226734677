import { ErrorHandler, Injectable, Injector, OnDestroy } from '@angular/core';
import { SubscriptionsContainer } from './shared/subscriptions-container';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap';
import { ConfirmModalComponent } from './ui-components/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {

  subs = new SubscriptionsContainer();
  bsModalRef: BsModalRef;
  bsModalService: BsModalService;

  constructor(private _injector: Injector) { }

  handleError(error: any): void {
    this.bsModalService = this._injector.get(BsModalService);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error)) {
      const initialState = {
        title: 'Reload Page',
        message: 'The page has expired.',
        okBtnMsg: 'Reload',
        hideClose: true
      };
      this.bsModalRef = this.bsModalService.show(ConfirmModalComponent, { ignoreBackdropClick: true, class: 'position-center', keyboard: false, initialState });
      this.subs.add = this.bsModalRef.content.onClose.subscribe(modalData => {
        if (modalData === true) {
          window.location.reload();
        }
      });
    } else {
      console.error(error);
    }
  }

  ngOnDestroy() {
    this.subs.dispose();
  }

}