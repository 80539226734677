import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OpportunityService} from '../../app/opportunities-container/opportunity.service';
import {OppoDetailsService} from '../../app/opportunity-details/oppo-details.service';
import {NodeList, NodeProviders} from '../../app/models/node-list';
import {Observable} from 'rxjs/Observable';
import {LocalDataProperties, State, StateProperties} from '../../app/models/state';
import {Opportunity, SiteListResponseObject} from '../../app/models/opportunity';
import {ModalMode} from '../../app/models/general-models';
import {LocalDataModel} from '../../app/models/local-data.model';
import {SimpleSizerStateProperties} from '../../app/commercial-sizer/models/simple-sizer-state.model';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  // init global state to safe defaults.
  state: State = {
    constants: {},
    locationModelList: [],
    nodes: {compute: [], normal: [], robo: []},
    resiliencyModelList: [],
    selectedSite: {
      site: {
        internal_notes: undefined,
        location: [],
        name: '',
        settings: undefined,
        site_note: '',
        tco_settings: undefined,
        workload_list: [],
        site: null,
        id: null,
        robo_site: false
      },
      result: []
    },
    selectedOpportunity: {
      id: -1,
      usecase: '',
      siteList: [],
      nodeConfig: {
        node_set: [],
        compute_node_set: [],
        node_configs: {}
      },
      settings: {
        resized: false,
        node_sizing: true,
        sizing_version: '',
        imported_opportunity: false
      }
    },
    siteList: []
  };

  private localData: LocalDataModel = {
    siteList: [],
    modalData: {
      site: null,
      type: '',
      mode: ModalMode.None
    }
  };

  private siteListSource: BehaviorSubject<SiteListResponseObject[]> = new BehaviorSubject<SiteListResponseObject[]>([]);
  siteList$: Observable<SiteListResponseObject[]> = this.siteListSource.asObservable();

  private selectedOpportunitySource = new BehaviorSubject<any>({});
  selectedOpportunity$ = this.selectedOpportunitySource.asObservable();

  private locationModelListSource = new BehaviorSubject<any[]>([]);
  locationModelList$ = this.locationModelListSource.asObservable();

  private constantsSource = new BehaviorSubject<any>({});
  constants$ = this.constantsSource.asObservable();

  private resiliencyModelListSource = new BehaviorSubject<any[]>([]);
  resiliencyModelList$ = this.resiliencyModelListSource.asObservable();

  private nodesSource = new BehaviorSubject<NodeList>({compute: [], normal: [], robo: []});
  nodes$ = this.nodesSource.asObservable();

  private siteSelectedSource: BehaviorSubject<SiteListResponseObject> = new BehaviorSubject<SiteListResponseObject>(this.state.selectedSite);
  selectedSite$: Observable<SiteListResponseObject> = this.siteSelectedSource.asObservable();

  private localDataSource: BehaviorSubject<LocalDataModel> = new BehaviorSubject<LocalDataModel>(this.localData);
  localData$: Observable<LocalDataModel> = this.localDataSource.asObservable();

  constructor(
    private opportunityService: OpportunityService,
    private opportunityDetailService: OppoDetailsService,
  ) {
    this.initData();
  }

  getSelectedSite() {
    const siteString = JSON.stringify(this.state.selectedSite);
    return JSON.parse(siteString);
  }

  onSiteDataChanged(sites?: SiteListResponseObject[]) {
    this.updateSiteList(sites);
  }

  onOpportunitySelected(selectedOpportunity: Opportunity) {
    this.updateState(StateProperties.selectedOpportunity, selectedOpportunity);
    this.selectedOpportunitySource.next(selectedOpportunity);
  }

  onOpportunityResized(resizedOpportunity: Opportunity) {
    this.updateState(StateProperties.selectedOpportunity, resizedOpportunity);
    this.selectedOpportunitySource.next(resizedOpportunity);
  }

  private initData() {
    this.updateState(StateProperties.locationModelList, this.opportunityDetailService.getLocationListData());
    this.locationModelListSource.next(this.state.locationModelList);

    this.updateState(StateProperties.constants, this.opportunityDetailService.fetchConstants());
    this.constantsSource.next(this.state.constants);

    this.updateState(StateProperties.resiliencyModelList, this.opportunityDetailService.getResiliencyListData());
    this.resiliencyModelListSource.next(this.state.resiliencyModelList);

    this.initNodesList();
  }

  private updateState(propertyToUpdated: StateProperties, valueToUpdate: any) {
    this.state = Object.freeze(Object.assign({}, this.state, Object.freeze({[propertyToUpdated]: valueToUpdate})));
  }

  public updateLocalData(propertyToUpdated: LocalDataProperties, valueToUpdate: any): void {
    this.localData = Object.freeze(Object.assign({}, this.localData, Object.freeze({[propertyToUpdated]: valueToUpdate})));
    this.localDataSource.next(this.localData);
  }

  private updateSiteList(siteList: any[] = []) {

    if (siteList && siteList.length > 0) {
      this.updateState(StateProperties.siteList, siteList);
      this.siteListSource.next(siteList);
    } else {
      if (this.state.selectedOpportunity) {
        this.opportunityDetailService
          .getSitesList(this.state.selectedOpportunity)
          .subscribe(sites => {
            this.updateState(StateProperties.siteList, sites);
            this.siteListSource.next(sites);
            // Update the selected site with updated data too.
            const foundSite = sites.find(e => e.site.id === this.state.selectedSite.site.id);
            if (foundSite) {
              this.onSiteSelected(foundSite);
            }
          }, error => {
            console.error(error);
          });
      } else {
        this.updateState(StateProperties.siteList, []);
        this.siteListSource.next([]);
      }
    }

  }

  public onNodesListUpdated(nodeList: NodeList) {
    this.updateState(StateProperties.nodes, nodeList);
    this.nodesSource.next(this.state.nodes);
  }

  private initNodesList() {

    this.opportunityService.getNodePrefList()
      .subscribe((nodeData: any) => {

        const nodesList: NodeList = {normal: [], compute: [], robo: []};

        for (const vendorName in nodeData.normal) {
          if (nodeData.normal.hasOwnProperty(vendorName)) {
            nodesList.normal.push({
              name: NodeProviders[vendorName],
              nodeList: nodeData.normal[vendorName]
            });
          }
        }

        for (const vendorName in nodeData.compute) {
          if (nodeData.compute.hasOwnProperty(vendorName)) {
            nodesList.compute.push({
              name: NodeProviders[vendorName],
              nodeList: nodeData.compute[vendorName]
            });
          }
        }

        this.onNodesListUpdated(nodesList);
      }, error => {
        console.error(error);
        this.onNodesListUpdated({normal: [], compute: [], robo: []});
      });

  }


  onSiteSelected(siteSelected: SiteListResponseObject) {

    if (siteSelected) {

      if (siteSelected.site.name !== 'aggregate') {
        const found = this.state.siteList.find(e => e.site.name === siteSelected.site.name);
        if (found) {
          this.updateState(StateProperties.selectedSite, found);
          this.siteSelectedSource.next(found);
        } else {
          this.updateState(StateProperties.selectedSite, {...siteSelected});
          this.siteSelectedSource.next({...siteSelected});

          const updateSites = this.state.siteList.concat([{...siteSelected}]);
          this.onSiteDataChanged(updateSites);

        }
      } else {
        this.updateState(StateProperties.selectedSite, {...siteSelected});
        this.siteSelectedSource.next({...siteSelected});
      }

    } else {
      console.error('Site selected got expected site but got undefined.');
    }

  }


  onNodeSetUpdated(nodeConfig: Opportunity['nodeConfig']) {

    if (nodeConfig) {
      const oppCopy = JSON.parse(JSON.stringify(this.state.selectedOpportunity));
      if (oppCopy) {
        oppCopy.nodeConfig = nodeConfig;
        this.updateState(StateProperties.selectedOpportunity, oppCopy);
        this.selectedOpportunitySource.next(oppCopy);
      }
    }

  }
  
  getNodeSource() {
    const temp = JSON.parse(JSON.stringify(this.nodesSource.getValue()))
    return temp;
  }

  getSelectedOpportunity(){
    const opp= JSON.parse(JSON.stringify(this.selectedOpportunitySource));
    return opp;
  }

  setLocationModelList(locationList: State[StateProperties.locationModelList]){
    this.updateState(StateProperties.locationModelList, [...locationList]);
  }

  setResiliencyList(resiliencyList: State[StateProperties.resiliencyModelList]){
    this.updateState(StateProperties.resiliencyModelList, [...resiliencyList]);
  }

  setConstants(constants: State[StateProperties.constants]){
    this.updateState(StateProperties.constants, constants);
  }

}
