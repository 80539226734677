import {Injectable, EventEmitter} from '@angular/core';
import {HttpService} from '../../shared/services/http/http.service';
import {RestService} from '../../shared/services/http/rest.service';
import {ConfigService} from '../shared/services/config.service';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class OppoDetailsService extends RestService {
  public onOpportunityChange: EventEmitter<any> = new EventEmitter();
  url = environment.http.baseUrl;
  queryName = 'SITE';
  ajaxLoaderEnabled = true;
  siteList = [];
  nodeList = {};
  useCasePref = [];
  selectedSite = {};
  siteNameMap = {};
  workloadSelectMap = {};
  aggregateWorkload = {};
  prePolicyList = [];
  localWarn = {};
  warnTCO: any = {};
  aggregateTCOTrack = false;
  pageData = {
    filterSelect: 'All',
    searchStr: '',
    selectedPolicy: [],
    isDesc: true

  };
  sizeSiteList: any = {};
  private resetTco = new BehaviorSubject({});
  currentresetTco = this.resetTco.asObservable();


  private nodeLstName = new BehaviorSubject('Cohesity');
  currentNodeLstName = this.nodeLstName.asObservable();

  private nodePrice = new BehaviorSubject('2500');
  currentNodePrice = this.nodePrice.asObservable();

  private downloadTco = new BehaviorSubject(false);
  currentdownloadTco = this.downloadTco.asObservable();

  constructor(httpService: HttpService, private configService: ConfigService) {
    super(httpService);
  }

  nodeLstNameChange(nodeLstName: string, nodePrice: string) {
    this.nodeLstName.next(nodeLstName);
    this.nodePrice.next(nodePrice);
  }

  resetTcoChange(resetTco: any) {
    this.resetTco.next(resetTco);
  }

  downloadTcoChange(downloadTco: boolean) {
    this.downloadTco.next(downloadTco);
  }

  getUrlForDownload() {
    return this.url;
  }

  getSelectedOpportunity(): any {
    return this.configService.getOpportunityData();
  }

  getSitesList(opportunity) {
    if (opportunity.id === undefined) {
      return;
    }
    this.baseUrl = this.url + '/opportunity/' + opportunity.id + '/site/';
    if (opportunity.resizeOption) {
      this.baseUrl += '?old=true';
    }
    return this.query();
  }

  getSitesListV2(opportunity) {
    if (opportunity.id === undefined) {
      return;
    }
    this.baseUrl = this.url + '/opportunity/' + opportunity.id + '/v2/site/';
    if (opportunity.resizeOption) {
      this.baseUrl += '?old=true';
    }
    return this.query();
  }

  getPrePolicyListData() {
    return this.configService.getPrepolicyListData();
  }

  getResiliencyListData() {
    return this.configService.getResiliencyListData();
  }

  getLocationListData() {
    return this.configService.getLocationListData();
  }

  getNodePrefListData() {
    return this.configService.getNodePrefListData();
  }


  addSite(id, payload) {
    //   this.warnTCO[id]=true;
    //   this.aggregateTCOTrack = true;
    this.baseUrl = this.url + '/opportunity/' + id + '/site/';
    delete payload.nodePrefList;
    delete payload.disableDelete;
    return this.save('', '', payload);
  }

  addSiteV2(id, payload) {
    //   this.warnTCO[id]=true;
    //   this.aggregateTCOTrack = true;
    this.baseUrl = this.url + '/opportunity/' + id + '/v2/site/';
    delete payload.nodePrefList;
    delete payload.disableDelete;
    return this.save('', '', payload);
  }

  cloneSite(id, payload) {
    //  this.warnTCO[id]=true;
    //  this.aggregateTCOTrack = true;
    this.baseUrl = this.url + '/opportunity/' + id + '/site/clone';
    delete payload.nodePrefList;
    delete payload.disableDelete;
    return this.save({id: payload.id}, '', payload);
  }

  cloneSiteV2(id, payload) {
    //  this.warnTCO[id]=true;
    //  this.aggregateTCOTrack = true;
    this.baseUrl = this.url + '/opportunity/' + id + '/v2/site/clone';
    delete payload.nodePrefList;
    delete payload.disableDelete;
    return this.save({id: payload.id}, '', payload);
  }

  siteOrderChange(id, payload) {
    this.baseUrl = this.url + '/opportunity/' + id + '/site/';
    return this.patch('', '', payload);
  }

  addEditWorkloadSite(id, payload) {

    this.warnTCO[payload.id] = true;
    this.aggregateTCOTrack = true;
    this.baseUrl = this.url + '/opportunity/' + id + '/site';
    delete payload.nodePrefList;
    delete payload.disableDelete;
    return this.update({id: payload.id}, '', payload);
  }

  addWorkload(id, siteId, payload) {
    this.baseUrl = this.url + '/opportunity/' + id + '/site/' + siteId + '/workload/';
    return this.save('', '', payload);
  }

  editWorkload(id, siteId, payload) {
    this.baseUrl = this.url + '/opportunity/' + id + '/site/' + siteId + '/workload';
    return this.update('', '', payload);
  }

  deleteWorkload(id, siteId, payload) {
    this.baseUrl = this.url + '/opportunity/' + id + '/site/' + siteId + '/workload';
    return this.delete('', '', payload);
  }

  deleteSite(id, payload) {
    this.warnTCO[id] = true;
    this.aggregateTCOTrack = true;
    this.baseUrl = this.url + '/opportunity/' + id + '/site';
    return this.delete({id: payload.id}, '');
  }

  downloadReport(id, payload) {
    this.baseUrl = this.url + '/opportunity/' + id + '/report';
    return this.save('', '', payload);
  }

  downloadTcoData(id, payload) {
    this.baseUrl = this.url + '/opportunity/' + id + '/tcoreport';
    return this.save('', '', payload);
  }

  downloadCloudArchive(oppId, id, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppId + '/site/' + id + '/csv';
    return this.save('', '', payload);
  }

  downloadCloudVault(oppId, id, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppId + '/site/' + id + '/csv';
    return this.save('', '', payload);
  }

  downloadCloudTier(oppId, id, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppId + '/site/' + id + '/csv';
    return this.save('', '', payload);
  }

  downloadCloudArchiveNas(oppId, id, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppId + '/site/' + id + '/csv';
    return this.save('', '', payload);
  }

  downloadStorageData(oppId, id, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppId + '/site/' + id + '/csv';
    return this.save('', '', payload);
  }

  resizeOpprtunity(id) {
    this.baseUrl = this.url + '/opportunity/' + id + '/resize';
    return this.save('', '', '');
  }

  getOpportunityById(id) {
    this.baseUrl = this.url + '/opportunity/' + id;
    return this.query();
  }

  editOpportunity(payload) {
    payload = JSON.parse(payload);
    this.baseUrl = this.url + '/opportunity';
    delete payload['resizeOption'];
    delete payload['adminFlag'];
    return this.update({id: payload.id}, '', payload);
  }

  changeOppoDataPatch(payload) {
    this.baseUrl = this.url + '/opportunity/';
    return this.patch('', '', payload);
  }

  addPolicy(oppoId, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/policy';
    return this.save('', '', payload);
  }

  editPolicy(oppoId, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/policy';
    return this.update('', '', payload);
  }

  deletePolicy(oppoId, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/policy';
    return this.delete('', '', payload);
  }

  generateTco(oppoId, siteId, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/site/' + siteId + '/tco';
    return this.save('', '', payload);
  }

  updateTcoDefaults(oppoId, siteId, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/site/' + siteId + '/tco?operation=override_defs';
    return this.update('', '', payload);
  }

  resetTcoDefaults(oppoId, siteId, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/site/' + siteId + '/tco?operation=set_defs';
    return this.update('', '', payload);
  }

  getNodeCompareListData(oppoId, siteId, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/site/' + siteId + '/nodecomparison';
    return this.save('', '', payload);
  }

  lazySizeOpportunity(oppoId, payload,isSimpleSizer=false, loaderId?: string) {
    if (environment.enableHeterogeneousClusters === false && !isSimpleSizer) {
      this.baseUrl = this.url + '/opportunity/' + oppoId + '/lazysizing';
    } else {
      this.baseUrl = this.url + '/opportunity/' + oppoId + '/v2/lazysizing';
    }
    return this.save('', '', payload, loaderId);
  }

  lazyBetaSizeOpportunity(oppoId) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/lazysizing';
    return this.get('', '');
  }

  setSiteListData(siteList) {
    this.siteList = siteList;
    this.siteList = this.siteList.map(site => {
      this.siteNameMap[site.site.id] = site.site.name;
      if (site.result.length > 0) {
        site.result = site.result.map(reWl => {
          reWl['node_pref'] = site.site.settings.node_preference;
          if (reWl.node_pref === undefined && site.settings.heterogenous) {
            reWl.node_pref = site.site.settings.node_config.new_node_preference[0];
          };
          return reWl;
        });
      }
      return site;
    });
  }

  getSiteMap() {
    return this.siteNameMap;
  }

  setSiteData(site) {
    this.selectedSite = site;
  }

  getSiteData() {
    return this.selectedSite;
  }

  setNodePrefLists(response) {
    this.nodeList = response;
  }

  setWarnTCO(response) {
    const temp = this.getTcoData();
    const tcoTemp: any = {};
    //change key id to key name
    for (const key in temp) {
      this.siteList.map((value) => {
        if (value.site.name == key) {
          tcoTemp[value.site.id] = temp[key];
        }
      });
    }

    for (const key in response) {
      if (tcoTemp[key] != undefined) {
        this.warnTCO[key] = response[key];
      }
    }
    this.aggregateTCOTrack = true;
  }

  setLocalWarn(warn) {
    for (const key in warn) {
      this.localWarn[key] = warn[key];
    }
    this.configService.setLocalWarn(this.localWarn);
  }

  getLocalWarn() {
    return this.configService.getLocalwarn();
  }

  setOpportunityList(oppoList) {
    this.configService.setOpportunityList(oppoList);
  }

  getOppoList() {
    return this.configService.getOpportunityList();
  }

  setWorkloadSelectMapData(workloadSelectMap) {
    this.workloadSelectMap = workloadSelectMap;
  }

  setStorage(storage) {
    this.configService.settcoStorage(storage);
  }

  getStorage() {
    return this.configService.gettcoStorage();
  }

  setTcoData(tcoData) {
    this.configService.setTcoData(tcoData);
  }

  getTcoData() {
    return this.configService.getTcoData();
  }

  setSiteNodeMap(siteNodeMap) {
    this.configService.setSiteNodeMap(siteNodeMap);
  }

  getSiteNodeMap() {
    return this.configService.getSiteNodeMap();
  }

  getWorkloadSelectMapData() {
    return this.workloadSelectMap;
  }

  aggregateWorkloadData(wlList, node_preference?) {
    const aggregateWorkload = {
      'name': 'aggregate',
      'wl_result': {
        'cloud_direct_requirements': [],
        'cloud_requirements': {},
        'fortknox_requirements': {},
        'cloud_requirements_v2': {},
        'requirements': [],
        'node_details': {},
        'compute_node_details': {},
        'node_errors': {}
      }
    };
    let siteResult = JSON.parse(JSON.stringify(wlList));
    siteResult = siteResult.filter(res => res.name !== 'ALL');
    siteResult.map(resData => {
      const wlResult = JSON.parse(JSON.stringify(resData.wl_result));

      if (environment.enableDataHawk === true) {

        for (const key in wlResult.compute_node_details) {
          if (aggregateWorkload.wl_result.compute_node_details.hasOwnProperty(key)) {
            aggregateWorkload.wl_result.compute_node_details[key] = this.addData(wlResult.compute_node_details[key], aggregateWorkload.wl_result.compute_node_details[key]);
          } else {
            aggregateWorkload.wl_result.compute_node_details[key] = wlResult.compute_node_details[key];
          }
        }

      } else {
        aggregateWorkload.wl_result.compute_node_details = {};
      }


      for (const key in wlResult.node_details) {
        if (aggregateWorkload.wl_result.node_details.hasOwnProperty(key)) {
          aggregateWorkload.wl_result.node_details[key] = this.addData(wlResult.node_details[key], aggregateWorkload.wl_result.node_details[key]);
        } else {
          aggregateWorkload.wl_result.node_details[key] = wlResult.node_details[key];
        }
      }
      if (aggregateWorkload.wl_result['cloud_direct_requirements'].length > 0) {
        const numOfDatas1 = aggregateWorkload.wl_result['cloud_direct_requirements'].length - 1;
        if (wlResult['cloud_direct_requirements'].length !== 0) {
          wlResult['cloud_direct_requirements'] = this.addData(wlResult.cloud_direct_requirements, aggregateWorkload.wl_result['cloud_direct_requirements'], 'cloud_direct_requirements');
        }
      } else {
        if (wlResult['cloud_direct_requirements'] !== undefined) {
          if (wlResult['cloud_direct_requirements'].length === 0) {
            for (let i = 0; i <= 60; i++) {
              wlResult.cloud_direct_requirements.push(0);
            }
          }
          aggregateWorkload.wl_result['cloud_direct_requirements'] = wlResult.cloud_direct_requirements;
        }
      }
      if (aggregateWorkload.wl_result['dmaas_yearly_avg'] !== undefined && aggregateWorkload.wl_result['dmaas_yearly_avg'].length > 0) {
        if (wlResult['dmaas_yearly_avg'].length !== 0) {
          wlResult['dmaas_yearly_avg'] = this.addData(wlResult.dmaas_yearly_avg, aggregateWorkload.wl_result['dmaas_yearly_avg'], 'dmaas_yearly_avg');
        }
      } else {
        if (wlResult.dmaas_yearly_avg !== undefined) {
          aggregateWorkload.wl_result['dmaas_yearly_avg'] = wlResult.dmaas_yearly_avg;
        }
      }
      if (aggregateWorkload.wl_result['requirements'].length > 0) {
        const numOfDatas1 = aggregateWorkload.wl_result['requirements'].length - 1;
        for (let i = 0; i <= numOfDatas1; i++) {
          wlResult['requirements'][i] = this.addData(wlResult.requirements[i], aggregateWorkload.wl_result['requirements'][i], 'requirements');
          if (!wlResult.requirements[i].hdd.hasOwnProperty('post_buffer')) {
            delete wlResult['requirements'][i].hdd.post_buffer;
          }
        }
      } else {
        aggregateWorkload.wl_result['requirements'] = wlResult.requirements;
      }
      //calculation for cloud_requirements

      for (const key in wlResult.cloud_requirements) {
        if (aggregateWorkload.wl_result.cloud_requirements.hasOwnProperty(key)) {
          if (wlResult.cloud_requirements[key].total !== undefined) {
            aggregateWorkload.wl_result.cloud_requirements[key] = this.addData(wlResult.cloud_requirements[key]['total'], aggregateWorkload.wl_result.cloud_requirements[key], 'cloud_req');
          } else {
            aggregateWorkload.wl_result.cloud_requirements[key] = this.addData(wlResult.cloud_requirements[key], aggregateWorkload.wl_result.cloud_requirements[key], 'cloud_req');
          }
        } else {
          if (wlResult.cloud_requirements[key].total !== undefined) {
            aggregateWorkload.wl_result.cloud_requirements[key] = wlResult.cloud_requirements[key].total;
          } else {
            aggregateWorkload.wl_result.cloud_requirements[key] = wlResult.cloud_requirements[key];
          }
        }
      }

      //calculation for cloud_requirements_v2
      for (const key in wlResult.cloud_requirements_v2) {
        if (aggregateWorkload.wl_result.cloud_requirements_v2.hasOwnProperty(key)) {
          if (wlResult.cloud_requirements_v2[key].total !== undefined) {
            aggregateWorkload.wl_result.cloud_requirements_v2[key] = this.addData(wlResult.cloud_requirements_v2[key]['total'], aggregateWorkload.wl_result.cloud_requirements_v2[key], 'cloud_req_v2');
          } else {
            aggregateWorkload.wl_result.cloud_requirements_v2[key] = this.addData(wlResult.cloud_requirements_v2[key], aggregateWorkload.wl_result.cloud_requirements_v2[key], 'cloud_req_v2');
          }
        } else {
          if (wlResult.cloud_requirements_v2[key].total !== undefined) {
            aggregateWorkload.wl_result.cloud_requirements_v2[key] = wlResult.cloud_requirements_v2[key].total;
          } else {
            aggregateWorkload.wl_result.cloud_requirements_v2[key] = wlResult.cloud_requirements_v2[key];
          }
        }
      }

      //Calculation for nas_warning
      for (const key in wlResult.node_errors) {
        if (aggregateWorkload.wl_result.node_errors.hasOwnProperty(key)) {
          aggregateWorkload.wl_result.node_errors[key] = this.addData(wlResult.node_errors[key], aggregateWorkload.wl_result.node_errors[key]);
        } else {
          aggregateWorkload.wl_result.node_errors[key] = wlResult.node_errors[key];
        }
      }
    });
    const nodeKeys = Object.keys(aggregateWorkload.wl_result.node_details);
    const numOfDatas1 = aggregateWorkload.wl_result.requirements.length - 1;
    nodeKeys.map(node => {
      for (let i = 0; i <= numOfDatas1; i++) {
        const nodeDet = aggregateWorkload.wl_result.node_details[node][i];
        let keys = (Object.keys(nodeDet.node_count)).sort();
        keys = keys.filter(k => {
          return k !== 'req_nodes' && k !== 'min_filewalk_nodes' && k !== 'init_hdd' && k !== 'init_throughput_max';
        });
        let max = nodeDet.node_count[keys[0]];
        keys.map(key => {
          max = nodeDet.node_count[key] > max ? nodeDet.node_count[key] : max;
        });
        aggregateWorkload.wl_result.node_details[node][i].node_count['req_nodes'] = max;
      }
    });

    return aggregateWorkload;
  }

  addData(data, objData, keyDet?) {
    for (const key in data) {
      if (typeof data[key] === 'number') {
        if (keyDet === 'node_count' || keyDet === 'requirements' || keyDet === 'effective_capacity') {
          if (key === 'minimum') {
            objData[key] = objData[key] > data[key] ? objData[key] : data[key];
          } else {
            try {
              if (objData[key] !== undefined) {
                objData[key] += data[key];
              } else {
                objData[key] = data[key];
              }
            } catch (e) {
            }
          }
        } else if (keyDet == 'cloud_req' || keyDet == 'cloud_req_v2' || keyDet === 'cloud_direct_requirements' || keyDet === 'dmaas_yearly_avg') {
          objData[key] += data[key];
        } else {
          objData[key] = data[key];
        }
      } else if (typeof data[key] === 'string') {
        objData[key] = data[key];
      } else if (typeof data[key] === 'object') {
        if (key === 'node_count' || key === 'effective_capacity') {
          objData[key] = this.addData(data[key], objData[key], key);
        } else {
          objData[key] = this.addData(data[key], objData[key], keyDet);
        }
      }
    }
    return objData;
  }

  getNodePrefLists() {
    return this.nodeList;
  }

  setUseCaseTypeLists(response) {
    const useCasePref = [];
    response.map(useCase => {
      const data = {
        name: useCase[0][0],
        key: useCase[0][1],
        subType: []
      };
      useCase[1].map(subData => {
        data.subType.push({
          name: subData[0],
          key: subData[1],
        });
      });
      useCasePref.push(data);
    });
    this.useCasePref = useCasePref;
  }

  getUseCaseTypeLists() {
    return this.configService.getUseCaseListData();
  }

  getSiteListData() {
    return this.siteList;
  }

  fetchConstants() {
    return this.configService.fetchConstants();
  }

  fetchUserDetails() {
    return this.configService.fetchUserDetails();
  }

  setOpportunityData(opportunity) {
    this.configService.setOpportunityData(opportunity);
    this.onOpportunityChange.emit({
      opportunity: opportunity
    });
  }

  setPolicyPageInfo(pageData) {
    this.pageData = pageData;
  }

  getPolicyPageInfo() {
    return this.pageData;
  }

  sizeSiteListChange(event) {
    this.sizeSiteList = event;
  }

  setNote(oppoId, payload) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/notes';
    return this.update('', '', payload, 'NOTES');
  }

  getNote(oppoId) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/notes';
    return this.query('', '', '');
  }

  getAggregateLicenseDetails(oppoId) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/site/0/newtco';
    return this.save('', '', {});
  }

  getSiteLicenseDetails(oppoId, siteId) {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/site/' + siteId + '/newtco';
    return this.save('', '', {});
  }

  getOpportunityBundleLicenseDetails(oppoId, type: 'bundle' | 'ala-carte' = 'bundle') {
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/api/bundle_license_calculator';
    return this.query('', '', {});
  }

  getOpportunityAggrResults(oppoId){
    if (!oppoId) {
      return;
    }
    this.baseUrl = this.url + '/opportunity/' + oppoId + '/aggrresults/'
    return this.query()
  }

}
