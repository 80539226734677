import {ChangedDataWorkloadModel, SimpleSizerWorkloadModel} from './simple-sizer-workload.model';
import {Opportunity} from '../../models/opportunity';
import {SiteData} from './simple-sizer-site-data.model';
import {SizerType} from '../../models/generic.model';

export enum SimpleSizerStateProperties {
  workloadList = 'workloadList',
  nodes = 'nodes',
  selectedVendor = 'selectedVendor',
  isLazySizingRequired = 'isLazySizingRequired',
  sizing_results = 'sizing_results',
  selectedOpportunity = 'selectedOpportunity',
  siteData = 'siteData',
  changedWorkloadData = 'changedWorkloadData',
  isSimpleSizer = 'isSimpleSizer',
  sizerType = 'sizerType',
  selectedNode = 'selectedNode',
}

export interface SimpleSizeNodes {
  compute: any[];
  normal: any[];
  robo: any[];
}

export interface SimpleSizerStateModel {
  [SimpleSizerStateProperties.selectedOpportunity]: Opportunity;
  [SimpleSizerStateProperties.workloadList]: SimpleSizerWorkloadModel[];
  [SimpleSizerStateProperties.nodes]: SimpleSizeNodes;
  [SimpleSizerStateProperties.selectedVendor]: { name: string, nodeList: string[][] };
  [SimpleSizerStateProperties.isLazySizingRequired]: boolean;
  [SimpleSizerStateProperties.sizing_results]: any;
  [SimpleSizerStateProperties.siteData]: SiteData;
  [SimpleSizerStateProperties.changedWorkloadData]: ChangedDataWorkloadModel;
  [SimpleSizerStateProperties.isSimpleSizer]: boolean;
  [SimpleSizerStateProperties.sizerType]: SizerType;
  [SimpleSizerStateProperties.selectedNode]: string;
}
