import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AjaxLoaderInterceptor } from './ajax-loader.interceptor';
import { ActiveAjaxLoadersService } from './active-ajax-loaders.service';
import { AjaxLoaderComponent } from './ajax-loader-component/ajax-loader.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        AjaxLoaderComponent
    ],
    exports: [
        AjaxLoaderComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        ActiveAjaxLoadersService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AjaxLoaderInterceptor,
            multi: true
        }
    ]
})
export class AjaxLoaderModule { }
