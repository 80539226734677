import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {HeaderComponent} from './header/header.component';
import {RouterConfigModule} from './app.router';
import {HttpServicesModule} from '../shared/services/http/http-services.module';
import {AjaxLoaderModule} from '../shared/ajax-loader/ajax-loader.module';
import {ConfigService} from './shared/services/config.service';
import {OpportunityService} from './opportunities-container/opportunity.service';
import {UiComponentsModule} from './ui-components/ui-components.module';
import {HttpModule} from '@angular/http';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsModalService, ModalModule} from 'ngx-bootstrap';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {SortablejsModule} from 'angular-sortablejs';
import {AnalyticsComponent} from './analytics/analytics.component';
import {OppCountGraphComponent} from './analytics/opp-count-graph/opp-count-graph.component';
import {PopularNodesGraphComponent} from './analytics/popular-nodes-graph/popular-nodes-graph.component';
import {AssignOppModalComponent} from './header/assign-opp-modal/assign-opp-modal.component';
import {TransferOppModalComponent} from './header/transfer-opp-modal/transfer-opp-modal.component';
import {HeaderService} from './header/header.service';
import {NodeInformatiomComponent} from './header/node-informatiom/node-informatiom.component';
import {ViewAsModalComponent} from './header/view-as-modal/view-as-modal.component';

import {CookieService} from 'ngx-cookie-service';
import {GlobalErrorHandler} from './global-error-handler';
import {DataStoreModule} from '../shared/data-store/data-store.module';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AnalyticsComponent,
    OppCountGraphComponent,
    PopularNodesGraphComponent,
    AssignOppModalComponent,
    TransferOppModalComponent,
    NodeInformatiomComponent,
    ViewAsModalComponent
  ],
  imports: [
    CommonModule,
    HttpModule,
    BrowserModule,
    NgxPaginationModule,
    RouterConfigModule,
    HttpServicesModule,
    AjaxLoaderModule,
    FormsModule,
    SharedModule,
    UiComponentsModule,
    DataStoreModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    SortablejsModule.forRoot({animation: 200}),
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    FormsModule
  ],
  providers: [
    ConfigService,
    OpportunityService,
    HeaderService,
    CookieService,
    {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AssignOppModalComponent,
    TransferOppModalComponent,
    ViewAsModalComponent
  ]
})
export class AppModule {
}
