import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from './shared/services/config.service';
import { SubscriptionsContainer } from './shared/subscriptions-container';
import { DataStoreService } from 'src/shared/data-store/data-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  subs = new SubscriptionsContainer();

  constructor(private configService: ConfigService, private dataStoreService: DataStoreService) { 
    this.subs.add = this.configService.changedOppList.subscribe(res => {
    })
  }

  ngOnInit() {
    this.configService.getConstants().subscribe((response) => {
      const constants = JSON.parse(response['_body']);
      this.configService.setConstants(constants)
      this.dataStoreService.setConstants(constants)
    });
    
  }

  changedOppList(event){
      this.configService.changedOppList.emit(event);
  }
  ngOnDestroy(){
    this.subs.dispose();
  }
}
