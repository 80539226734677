import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AnalyticsService } from '../analytics/analytics.service';
import { OpportunityService } from '../opportunities-container/opportunity.service';
import { ConfigService } from '../shared/services/config.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { DatePipe } from '@angular/common';
import { SubscriptionsContainer } from 'src/app/shared/subscriptions-container';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
  providers: [DatePipe]
})
export class AnalyticsComponent implements OnInit, OnDestroy {
  @ViewChild('downloadInputAnalytics') downloadInputAnalysis: ElementRef;
  
  thisYearDate: Date = new Date();
  year = this.thisYearDate.getFullYear();
  month = this.thisYearDate.getMonth();
  day = this.thisYearDate.getDate();
  lastYearDate: Date = new Date(this.year - 1, this.month, this.day);
  minDateDefault: Date = new Date();
  maxDateDefault: Date = new Date();
  datePickerConfigMin: Partial<BsDatepickerConfig>;
  myInnerHeight: any;
  currentDate: string;
  lastDate: string;
  startYear: number;
  startMonth: number;
  startDay: number;
  endYear: any;
  endMonth: any;
  endDay: any;
  month_wise_forward_opp_count: any;
  month_wise_reverse_opp_count: any;
  month_wise_active_user_count: any;
  popularNodes: boolean = false;
  popular_nodes: any;
  tabChange: any;
  downloadFlag: boolean;
  Opprecords: any;
  errMsg: any;
  downloadPath: string;
  downloadUrl: string;
  subs = new SubscriptionsContainer();

  constructor(private analyticservice: AnalyticsService, private configService: ConfigService, private opportunityService: OpportunityService, private datePipe: DatePipe, private router: Router) {
    this.downloadUrl = this.analyticservice.getUrlForDownload();
    this.currentDate = this.datePipe.transform(this.thisYearDate, 'dd/MM/yyyy');
    this.lastDate = this.datePipe.transform(this.lastYearDate, 'dd/MM/yyyy');
    this.datePickerConfigMin = Object.assign({}, {
      isAnimated: true,
      showWeekNumbers: false,
      dateInputFormat: 'DD/MM/YYYY',
      containerClass: 'theme-blue',
      minDate: new Date(this.year - 1, this.month, this.day),
      maxDate: new Date(this.year, this.month, this.day)
    });
  }
  onResize(event) {
    this.windowResizeScroll();
  }
  private windowResizeScroll() {
    this.myInnerHeight = window.innerHeight - 50;
  }

  onLastDateChange(event) {
    if(event){
      this.startYear = event.getFullYear();
      this.startMonth = event.getMonth();
      this.startDay = event.getDate();
    }
  }

  onCurrentDateChange(event) {
    if(event){
      this.endYear = event.getFullYear();
      this.endMonth = event.getMonth();
      this.endDay = event.getDate();
    }
  }

  ngOnInit() {
    this.windowResizeScroll();
    this.tabChange = 'foroppCount';
    this.startDay = this.endDay = this.day;
    this.startMonth = this.endMonth = this.month;
    this.startYear = this.year - 1;
    this.endYear = this.year;
    this.popularNodes = false;
    this.onUpdateGraph();
  }

  onUpdateGraph() {
    let payload = {
      "node_info": this.popularNodes,
      "generate_csv": false,
      "start_date": {
        "day": this.startDay,
        "month": this.startMonth + 1,
        "year": this.startYear
      },
      "end_date": {
        "day": this.endDay,
        "month": this.endMonth + 1,
        "year": this.endYear
      }
    }
    this.subs.add = this.analyticservice.getOpportunitiesCount(payload).subscribe((response) => {
      var i = this.Opprecords = 0;
      this.month_wise_forward_opp_count = response.month_wise_forward_opp_count;
      this.month_wise_reverse_opp_count = response.month_wise_reverse_opp_count;
      this.month_wise_active_user_count = response.month_wise_active_user_count
      this.popular_nodes = response.popular_nodes;
      for(let i = 0; i <Object.keys(this.month_wise_forward_opp_count).length; i++){
        this.Opprecords = this.Opprecords + this.month_wise_forward_opp_count[i][1];
      }
      for(let i = 0; i <Object.keys(this.month_wise_reverse_opp_count).length; i++){
        this.Opprecords = this.Opprecords + this.month_wise_reverse_opp_count[i][1];
      }
    }, error => {
      this.errMsg = error.error['error_msg'];
    });
  }

  tabSelect(tabLabel) {
    this.tabChange = tabLabel;
  }

  receiveNodeMessage($event) {
    setTimeout(() => this.downloadFlag = $event, 0);
  }

  receiveOppMessage($event) {
    setTimeout(() => this.downloadFlag = $event, 0);
  }

  navigateHome() {
    this.router.navigate(['/opportunities']);
  }

  onStartDateEntered(){
    // var isvalid = this.isValidDate(this.lastDate);
    // console.log("isvalid", isvalid);
  }


  // isValidDate(dateString){
  //   // First check for the pattern
  //   if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
  //       return false;

  //   // Parse the date parts to integers
  //   var parts = dateString.split("/");
  //   var day = parseInt(parts[1], 10);
  //   var month = parseInt(parts[0], 10);
  //   var year = parseInt(parts[2], 10);

  //   // Check the ranges of month and year
  //   if(year < 1000 || year > 3000 || month == 0 || month > 12)
  //       return false;

  //   var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  //   // Adjust for leap years
  //   if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
  //       monthLength[1] = 29;

  //   // Check the range of the day
  //   return day > 0 && day <= monthLength[month - 1];
  // }

  enableValidation(form){
    const self = this;
    if (form.invalid) {
      return true;
    }
    if(this.lastDate === undefined || this.lastDate.length ===0){
      return true;
    }
    if(this.currentDate === undefined || this.currentDate.length === 0){
      return true;
    }
  }

  downloadAnalyticsData(){
    let payload = {
      "node_info": false,
      "generate_csv": true,
      "start_date": {
        "day": this.startDay,
        "month": this.startMonth + 1,
        "year": this.startYear
      },
      "end_date": {
        "day": this.endDay,
        "month": this.endMonth + 1,
        "year": this.endYear
      }
    }
    this.subs.add = this.analyticservice.getOpportunitiesCount(payload).subscribe((response) => {
      if (response !== null || response !== undefined) {
        this.downloadPath = this.downloadUrl + '/opportunity/report/download?fname=' + response.file_name;
        setTimeout(() => {
          this.downloadInputAnalysis.nativeElement.click();
        }, 500);
      }
    }, error => {
      this.errMsg = error.error['error_msg'];
    });
  }
  ngOnDestroy() {
		this.subs.dispose();
	}

}
